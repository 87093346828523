:root {
  --primary-clr: #000000;
  --secondary-clr: #14b8a6;
  --primary-border-radius: 6px;
  --secondary-border-radius: 8px;
}
@import "./layout/PublicLayout.css";
@import "./checkout.css";
@import "./reactStrapCustomElement.css";
@import "./reactStrapCustomElementMedia.css";
@import "./lodar.css";
@import "./product.css"

