.public-layout-header {
  background-color: var(--primary-clr);
  padding: 15px 67px;
  box-shadow: 5px 20px 20px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  width: 100%;
  transition: padding 0.2s;
  z-index: 10;
}

.public-layout-header.public-layout-header-scroll-time {
  padding: 20px 80px;
  box-shadow: 5px 20px 20px rgb(152 222 225 / 20%);
}

.home-header-glow {
  box-shadow: 5px 20px 20px #c9f7ed !important;
}

.header-logo {
  background-size: 100%;
  /* margin-left: 10px; */
  margin-left: 33px;
  margin-right: 10px;
  width: 200px;
  height: 50px;
  transition: width 0.2s;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  transition: all .2s ease-in-out;
}

.header-logo-scroll-time {
  width: 90px;
  margin-left: 20px;
  transition: all .2s ease-in-out;
  height: auto;
}

.beta-sup {
  color: var(--bs-black);
  bottom: -10px;
  left: -23px;
  background: var(--bs-white);
  border-radius: 10px;
  padding: 2px 5px;
}

.beta-sup-scroll-time {
  bottom: -2px;
  left: -15px;
  font-size: 8px;
  padding: 0px 3px;
}

.public-layout-body {
  margin-top: 80px;
  min-height: calc(100vh - 0px);
  overflow-x: hidden;
}

.scroll-to-top-Button {
  position: fixed;
  bottom: 0;
  right: 30px;
  width: 48px;
  box-shadow: 2px 2px 3px #999;
  border-radius: 6px 6px 0 0 !important;
}

.nav-collapse-menu-icon {
  margin-right: 32px;
}

.nav-collapse-menu-icon svg {
  cursor: pointer;
}

.confirmation-thanks-container .confirmation-thanks-title {
  font-size: 60px;
}

.confirmation-thanks-container .confirmation-thanks-subtitle {
  font-size: 30px;
  margin-bottom: 20px;
}

.confirmation-thanks-container .confirmation-thanks-paragraph {
  font-size: 20px;
}

.confirmation-thanks-container .confirmation-info-card .info-card-title {
  text-align: left;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: var(--bs-black);
  margin-bottom: 0;
}

.confirmation-thanks-container .confirmation-info-card .info-card-icon {
  font-size: 28px;
  margin-right: 18px;
}

.confirmation-thanks-container .confirmation-member-card {
  border: 2px solid var(--primary-clr);
  border-radius: 0;
  padding: 10px 4px;
  height: 315px;
  margin: 20px 0;
}

.confirmation-thanks-container .confirmation-member-card .member-card-title {
  text-align: left;
  font-size: 25px;
  display: grid;
  width: 100%;
  margin-bottom: 15px;
  color: var(--bs-black);
}

.confirmation-thanks-container .confirmation-member-card .member-card-text {
  height: 167px;
  overflow-y: auto;
}

.confirmation-thanks-container .confirmation-member-card .member-card-icon {
  font-size: 32px;
  margin-bottom: 18px;
}

.confirmation-thanks-container .confirmation-member-card .member-card-icon.ml-5 {
  margin-right: 48px;
  margin-left: 23px;
  margin-bottom: 0;
}

.confirmation-thanks-container .confirmation-member-card .member-card-text h6 {
  font-size: 17px;
  color: #333333;
  text-align: left;
}

.confirmation-thanks-container .confirmation-member-card .order-card-title {
  text-align: left;
  font-size: 38px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  color: var(--bs-black);
  border-bottom: 2px solid var(--bs-black);
}

.member-card-border {
  border-bottom: 1px solid var(--bs-black);
  opacity: 1;
}

.btn-vav-login {
  color: #fff !important;
  background-color: var(--secondary-clr) !important;
  border-color: var(--secondary-clr) !important;
  padding: 11px 13px !important;
  font-weight: 700 !important;
  width: 198px;
  height: 44px;
  display: flex !important;
  align-items: center;
  position: absolute;
  right: 0;
  margin-right: 101px;
  transition: height 0.2s;
}

.btn-vav-login-scroll-time {
  height: 38px;
  top: 13px;
}

.btn-vav-login .btn-label {
  position: relative;
  background: rgba(0, 0, 0, 0.05);
  padding: 14px;
  left: -14px;
  top: 0;
  border-radius: 0.2rem 0 0 0.2rem;
  font-size: 16px;
  line-height: 0;
}

.btn-vav-login:active,
.btn-vav-login::selection,
.btn-vav-login:hover {
  color: var(--secondary-clr) !important;
  background-color: #fff !important;
  border-color: var(--secondary-clr) !important;
}