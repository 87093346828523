@media screen and (max-width: 1240px) and (min-width: 1042px) {
    .membership-card-title-content {
        padding: 15px;
    }

    .membership-card-benefit-content {
        height: 345px;
    }

    .membership-card-title {
        font-size: 25px;
    }
}

@media screen and (max-width: 1041px) and (min-width: 992px) {
    .membership-card-title-content {
        padding: 10px;
    }

    .membership-card-title {
        font-size: 23px;
    }

    .membership-card-benefit-content {
        height: 345px;
    }

    .membership-card-benefit-content span {
        font-size: 11px;
    }

    .value-product-membership-card button {
        font-size: 12px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 1080px) {
    .header-logo {
        width: 124px;
    }

    .header-logo-scroll-time {
        width: 90px;
    }

    .public-layout-header {
        padding: 15px 77px;
    }

    .public-layout-body {
        margin-top: 60px;
    }

    .joining-title-center.onboarding-title-2 h1,
    .joining-title-center h1 {
        font-size: 25px;
    }

    .checkout-next-pre-button.btn-primary.btn-lg {
        padding: 12px 40px;
    }
}

@media screen and (max-width: 1528px) and (min-width: 1453px) {
    .membership-card-title-content {
        min-height: 295px;
        display: grid;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 1350px) {
    .onboarding-checkout-children-table tbody.onboarding-checkout-children-table-tbody input.form-control {
        width: auto;
    }

    .onboarding-checkout-children-table tbody.onboarding-checkout-children-table-tbody select.form-select {
        width: auto;
    }

    .onboarding-area2.plain .pricing .single-item .pricing-item ul {
        min-height: 610px;
    }

    .onboarding-area2 .pricing-header h2 {
        font-size: 40px;
    }

    .onboarding-checkout-content .secure-check-title h1 {
        font-size: 37px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h2.text-info-green {
        font-size: 35px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5.text-black {
        font-size: 16px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5 {
        font-size: 16px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h4 {
        font-size: 20px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h3 {
        font-size: 19px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer h3.assistance-text {
        font-size: 17px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer a {
        font-size: 16px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .spouse-info-col {
        width: 50%;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-text h6 {
        font-size: 16px;
    }
}

@media (max-width: 1323px) {
    .onboarding-area2 .pricing-title-center h1 {
        font-size: 50px;
    }

    .hero-sec-title {
        font-size: 50px;
    }

    .value-product-title,
    .value-member-title {
        font-size: 42px;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title {
        font-size: 39px;
    }

    .onboarding-area2 .pricing-header h2 {
        font-size: 35px;
    }

    .onboarding-checkout-content .secure-check-title h1 {
        font-size: 35px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h2.text-info-green {
        font-size: 34px;
    }

    .confirmation-thanks-container .confirmation-thanks-title {
        font-size: 55px;
    }

    .confirmation-thanks-container .confirmation-thanks-subtitle {
        font-size: 25px;
    }

    .confirmation-thanks-container .confirmation-member-card .order-card-title {
        font-size: 35px;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-text h6 {
        font-size: 15px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-title,
    .confirmation-thanks-container .confirmation-thanks-paragraph {
        font-size: 18px;
    }
}

@media (max-width: 1266px) {

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row {
        padding: 48px 50px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 50px;
    }

    .onboarding-area2 .pricing-header h2 {
        font-size: 33px;
    }

    .onboarding-checkout-content .secure-check-title h1 {
        font-size: 31px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h2.text-info-green {
        font-size: 30px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5.text-black {
        font-size: 16px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5 {
        font-size: 16px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h4 {
        font-size: 20px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h3 {
        font-size: 19px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer h3.assistance-text {
        font-size: 17px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer a {
        font-size: 16px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-title {
        font-size: 16px;
    }

    .confirmation-thanks-container .confirmation-thanks-paragraph {
        font-size: 16px;
    }
}

@media (max-width: 1257px) {

    .w-mbl-radio-img.w-100 {
        height: 200px;
    }
}

@media (max-width: 1230px) {

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row {
        padding: 48px 53px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 53px;
    }
}

@media (max-width: 1223px) {
    .onboarding-area2 .pricing-title-center h1 {
        font-size: 46px;
    }

    .hero-sec-title {
        font-size: 46px;
    }

    .value-product-title,
    .value-member-title {
        font-size: 38px;
    }

    .onboarding-area2 .pricing-header h2 {
        font-size: 33px;
    }

    .onboarding-area2 .pricing-header h2 sub {
        font-size: 18px;
    }

    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody th {
        min-width: 365px;
    }

    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody td.plan-details-btn-container,
    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody td.enroll-btn-container {
        padding: 8px 3px !important;
        width: 30%;
    }

    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody td.plan-details-btn-container button.plan-details-btn {
        width: 100%;
    }

    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody td.enroll-btn-container button.enroll-btn {
        width: 100%;
    }

    .onboarding-checkout-content .secure-check-title h1 {
        font-size: 30px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h2.text-info-green {
        font-size: 29px;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-text h6 {
        font-size: 13px;
    }
}

@media (max-width: 1214px) {
    .premier-question-accordion-content .premier-question-accordion-item .accordion-button span {
        font-size: 14px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-body {
        font-size: 12px;
    }
}

@media (max-width: 1204px) {
    .onboarding-area2 .pricing-header h2 {
        font-size: 30px;
    }
}

@media (max-width: 1182px) {
    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title {
        font-size: 37px;
    }
}

@media (max-width: 1170px) {

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row {
        padding: 48px 55px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 55px;
    }

    .onboarding-area2 .pricing-header h3 {
        font-size: 39px;
    }

    .onboarding-area2 .pricing-header h4 {
        font-size: 22px;
    }

    .onboarding-area2 .pricing-header h2 sub {
        font-size: 15px;
    }

    .onboarding-area2 .pricing-header h2 {
        font-size: 28px;
    }

    .onboarding-area2 .pricing-item li {
        font-size: 12px;
        line-height: 20px;
    }

    .onboarding-area2.plain .pricing .single-item .pricing-item ul {
        min-height: 500px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-title {
        font-size: 15px;
    }

    .confirmation-thanks-container .confirmation-thanks-paragraph {
        font-size: 15px;
    }

    .onboarding-compare-table-area2 table {
        min-width: 1200px;
        width: 100%;
    }

    .w-mbl-radio-img.w-100 {
        height: auto;
    }
}

@media (max-width: 1143px) {
    .onboarding-area2 .pricing-title-center h1 {
        font-size: 40px;
    }

    .hero-sec-title {
        font-size: 40px;
    }

    .value-product-title,
    .value-member-title {
        font-size: 32px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-icon {
        font-size: 23px;
        margin-right: 12px;
    }
}

@media (max-width: 1132px) {
    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title {
        font-size: 35px;
    }
}

@media (max-width: 1130px) {

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row {
        padding: 48px 58px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 58px;
    }

    .onboarding-area2 .pricing-header h2 sub {
        font-size: 14px;
    }

    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody th {
        min-width: 335px;
    }

    .onboarding-area2-dental .onboarding-dental-content-left h3,
    .onboarding-area2-dental .onboarding-dental-content-left p {
        font-size: 17px;
    }

    .onboarding-area2-dental .onboarding-dental-content-left .kit-container .kit-container-list .kit-list {
        font-size: 15px;
    }

    .onboarding-checkout-content .secure-check-title h1 {
        font-size: 29px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h2.text-info-green {
        font-size: 28px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5.text-black {
        font-size: 16px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5 {
        font-size: 16px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h4 {
        font-size: 20px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h3 {
        font-size: 19px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer h3.assistance-text {
        font-size: 17px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer a {
        font-size: 16px;
    }
}

@media (max-width: 1101px) {
    .premier-question-accordion-content .premier-question-accordion-item .accordion-button span {
        font-size: 12px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-body {
        font-size: 10px;
    }
}

@media (max-width: 1092px) {

    .confirmation-thanks-container .confirmation-info-card .info-card-title,
    .confirmation-thanks-container .confirmation-thanks-paragraph {
        font-size: 14px;
    }
}

@media (max-width: 1085px) {
    .onboard-pricing-text-desktop {
        display: none;
    }

    .onboard-pricing-text-mbl {
        display: grid;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title {
        font-size: 39px;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title .pricing-text-underline svg.pricing-text-underline-img {
        width: calc(50% + 0.75em);
        left: 50%;
    }
}

@media (max-width: 1061px) {
    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody th {
        min-width: 310px;
    }

    .onboarding-area2-dental .onboarding-dental-content-left h3,
    .onboarding-area2-dental .onboarding-dental-content-left p {
        font-size: 16px;
    }

    .onboarding-area2-dental .onboarding-dental-content-left .kit-container .kit-container-list .kit-list {
        font-size: 14px;
    }
}

@media (max-width: 1050px) {
    .onboarding-area2 .pricing-title-center h2 {
        font-size: 18px;
    }

    .below-content .hero-sec-subtitle,
    .hero-sec-subtitle {
        font-size: 22px;
    }

    .onboarding-area2 .pricing-header h4 {
        font-size: 21px;
    }

    .onboarding-area2 .pricing-header h2 sub {
        font-size: 14px;
    }

    .onboarding-area2 .pricing-header h2 {
        font-size: 26px;
    }

    .onboarding-area2 .pricing-item li {
        font-size: 11px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-title,
    .confirmation-thanks-container .confirmation-thanks-paragraph {
        font-size: 18px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-icon {
        font-size: 26px;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-title {
        font-size: 23px;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-icon {
        font-size: 30px;
    }

    .confirmation-thanks-container .confirmation-member-card .order-card-title {
        font-size: 33px;
    }
}

@media (max-width: 1039px) {

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row {
        padding: 48px 62px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 62px;
    }

    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody td.plan-details-btn-container button.plan-details-btn {
        padding: 10px 0 !important;
    }

    .onboarding-checkout-content .secure-check-title h1 {
        font-size: 28px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h2.text-info-green {
        font-size: 27px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5.text-black {
        font-size: 15px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5 {
        font-size: 15px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h4 {
        font-size: 19px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h3 {
        font-size: 18px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer h3.assistance-text {
        font-size: 16px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer a {
        font-size: 15px;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title .pricing-text-underline svg.pricing-text-underline-img {
        width: calc(60% + 0.75em);
    }
}

@media (max-width: 1023px) {
    .onboarding-area2 .pricing-title-center h1 {
        font-size: 35px;
    }

    .hero-sec-title {
        font-size: 35px;
    }

    .value-product-title,
    .value-member-title {
        font-size: 27px;
    }

    .onboarding-area2.plain .pricing .single-item .pricing-item kbd.popular-tag {
        padding: 7px 20px;
    }

    .onboarding-area2 .pricing-header h4 {
        font-size: 20px;
    }

    .onboarding-area2 .pricing-header h2 sub {
        font-size: 13px;
    }

    .onboarding-area2 .pricing-header h2 {
        font-size: 26px;
    }
}

@media (max-width: 1021px) {
    .premier-question-accordion-content .premier-question-accordion-item .accordion-button span {
        font-size: 11.5px;
    }
}

@media (max-width: 992px) {
    .public-layout-header {
        padding: 20px 14px;
    }

    .public-layout-header.public-layout-header-scroll-time {
        padding: 20px 28px;
    }

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row {
        padding: 48px 7px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 7px;
    }

    .onboarding-area2,
    .onboarding-checkout-content {
        padding: 48px;
    }

    .onboarding-content {
        padding: 48px;
    }

    .value-benefit-row {
        padding-left: 48px;
        padding-right: 48px;
    }

    .onboarding-content.product-pre-content {
        padding: 48px;
    }

    .onboarding-area2 .fixed-shape img {
        width: 80%;
        height: 80%;
    }

    .onboarding-area2 .pricing-header h2 sub {
        font-size: 16px;
    }

    .onboarding-area2 .pricing-header h2 {
        font-size: 28px;
    }

    .onboarding-area2.plain .pricing .single-item .pricing-item .btn-onboarding2 {
        padding: 10px !important;
    }

    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody th {
        min-width: 310px;
    }

    .scroll-to-top-Button {
        right: 7px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row {
        margin: 0;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-tab-sm-12 {
        padding: 0;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-icon {
        font-size: 26px;
    }
}

@media (max-width: 991px) {
    .onboarding-area2 .pricing-header h4 {
        font-size: 24px;
    }

    .onboarding-area2 .pricing-header h2 {
        font-size: 31px;
    }

    .onboarding-area2.plain .pricing .single-item .pricing-item .btn-onboarding2 {
        font-size: 18px;
    }

    .onboarding-area2 .pricing-footer button.text-uppercase.text-black {
        font-size: 14px;
    }

    .onboarding-area2 .pricing-item li {
        font-size: 13px;
    }

    .onboarding-area2-dental .onboarding-dental-content-left {
        padding-left: 0;
        padding-right: 0;
        order: 2;
    }

    .onboarding-area2-dental .onboarding-dental-content-right {
        padding-left: 0;
        padding-right: 0;
        order: 1;
    }

    .onboarding-area2-dental .onboarding-dental-content-left h2 {
        margin-top: 40px;
        text-align: center;
    }

    .onboarding-area2-dental .onboarding-dental-content-left h2.mb-4 {
        margin-top: 15px;
        font-size: 20px;
    }

    .onboarding-area2-dental .onboarding-dental-content-left .kit-container {
        display: flex;
        width: 100%;
    }

    .onboarding-area2-dental .onboarding-dental-content-left .kit-container .kit-container-pera {
        width: 50%;
        padding-right: 10px;
    }

    .onboarding-area2-dental .onboarding-dental-content-left .kit-container .kit-container-list {
        width: 50%;
        margin: 0 !important;
        border-left: 2px solid #20c3b1;
        padding-left: 10px;
    }

    .onboarding-area2-dental .onboarding-dental-content-left h3,
    .onboarding-area2-dental .onboarding-dental-content-left p {
        font-size: 18px;
    }

    .onboarding-area2-dental .onboarding-dental-content-left .kit-container .kit-container-list .kit-list {
        font-size: 14px;
    }

    .onboarding-area2-dental .onboarding-dental-content-left button.btn-secondary {
        width: 80%;
        margin: 45px auto 0 auto;
    }

    .onboarding-area2.plain .pricing .single-item {
        margin-bottom: 48px;
    }

    .onboarding-area2.plain .pricing .single-item:nth-child(3) {
        margin-bottom: 0;
    }

    .onboarding-area2 .pricing-footer button.text-uppercase.text-black.pricing-plan-btn {
        display: block;
    }

    .row.onboarding-compare-d-none {
        display: none
    }

    .onboarding-area2-dental.bg-wave {
        background: var(--secondary-clr);
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title {
        margin-top: 0;
    }


    .btn-modal-submit.modal-submit-btn-xs {
        width: 100%;
    }

    .onboarding-area2.plain .pricing .single-item.max {
        order: 1;
        margin-bottom: 48px;
    }

    .onboarding-area2.plain .pricing .single-item.pro {
        order: 2;
        margin-bottom: 48px;
    }

    .onboarding-area2.plain .pricing .single-item.ocp {
        order: 3;
        margin-bottom: 0;
    }

    .mbl-child-card-responsive.card {
        display: block;
    }

    .desktop-child-table-responsive {
        display: none;
    }

    .child-input-col {
        padding-left: 12px !important;
    }

    .child-input-col button {
        padding-right: 12px !important;
    }

    .w-mbl-radio-img.w-100 {
        height: 200px;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title {
        margin-top: 120px;
    }

    .value-benefit-text {
        margin: 40px 0;
    }

    .value-benefit-img {
        margin: 40px 5px;
    }

    .value-benefit-img img {
        width: 100%;
    }
}

@media (max-width: 949px) {

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row {
        padding: 48px 9px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 9px;
    }

    .nav-collapse-menu-icon {
        margin-right: 18px;
    }

    .onboarding-area2 .pricing-header h2 sub {
        font-size: 15px;
    }

    .onboarding-checkout-content .secure-check-title h1 {
        font-size: 27px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h2.text-info-green {
        font-size: 26px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5.text-black {
        font-size: 14px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5 {
        font-size: 14px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h4 {
        font-size: 18px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h3 {
        font-size: 17px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer h3.assistance-text {
        font-size: 15px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer a {
        font-size: 14px;
    }
}

@media (max-width: 935px) {

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row {
        padding: 48px 10px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 10px;
    }

    .onboarding-area2 .pricing-header h2 sub {
        font-size: 14px;
    }

    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody td.enroll-btn-container button.enroll-btn {
        padding: 16px 5px !important;
    }

    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody th {
        min-width: 290px;
    }
}

@media (max-width: 847px) {

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row {
        padding: 48px 14px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 14px;
    }

    .onboarding-area2 .pricing-header h2 sub {
        font-size: 13px;
    }

    .onboarding-area2 .pricing-header h5 {
        font-size: 13px;
    }

    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody th {
        min-width: 265px;
    }

    .onboarding-area2-dental .onboarding-dental-content-left button.btn-secondary {
        font-size: 15px;
        padding: 13px;
    }
}

@media (max-width: 827px) {
    .onboarding-area2 .pricing-title-center h2 {
        font-size: 15px;
    }

    .below-content .hero-sec-subtitle,
    .hero-sec-subtitle {
        font-size: 19px;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title .pricing-text-underline svg.pricing-text-underline-img {
        width: calc(66% + 0.75em);
    }
}

@media (max-width: 820px) {
    .onboarding-area2 .pricing-title-center h1 {
        font-size: 33px;
    }

    .hero-sec-title {
        font-size: 33px;
    }

    .value-product-title,
    .value-member-title {
        font-size: 25px;
    }

    .onboarding-area2.plain .pricing .single-item .pricing-item kbd.popular-tag {
        padding: 6px 20px;
    }

    .onboarding-area2 .pricing-header h2 sub {
        font-size: 12px;
    }

    .onboarding-area2 .pricing-header h5 {
        font-size: 12px;
    }

    .onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody th {
        min-width: 240px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-title,
    .confirmation-thanks-container .confirmation-thanks-paragraph {
        font-size: 16px;
    }

    .confirmation-thanks-container .confirmation-thanks-subtitle {
        font-size: 23px;
    }
}

@media (max-width: 806px) {
    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .pricing-cards-img {
        width: 100%;
    }
}

@media (max-width: 783px) {

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row {
        padding: 48px 17px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 17px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-title,
    .confirmation-thanks-container .confirmation-thanks-paragraph {
        font-size: 15px;
    }
}

@media (max-width: 778px) {
    .onboarding-area2-dental .onboarding-dental-content-left .kit-container .kit-container-pera {
        width: 100%;
        padding-right: 0px;
        text-align: center;
    }

    .onboarding-area2-dental .onboarding-dental-content-left .kit-container .kit-container-list {
        width: 100%;
        margin: 24px 0 !important;
        border: none;
        padding-left: 0;
        display: grid;
        justify-content: center;
    }

    .onboarding-area2-dental .onboarding-dental-content-left .kit-container {
        display: block;
    }
}

/* one screen start */
@media (max-width: 767px) {
    .onboarding-area2.plain .pricing-card-row {
        background: transparent;
        border-radius: 0;
    }

    .onboarding-area2.plain .pricing .single-item .pricing-footer,
    .onboarding-area2.plain .pricing .single-item:nth-child(3) .pricing-footer,
    .onboarding-area2.plain .pricing .single-item:nth-child(1) .pricing-footer {
        border-radius: 0 0 8px 8px;
    }

    .onboarding-area2.plain .pricing .single-item:nth-child(1) .pricing-item,
    .onboarding-area2.plain .pricing .single-item:nth-child(3) .pricing-item {
        box-shadow: 5px 25px 25px #00000033;
    }

    .onboarding-area2.plain .pricing .single-item .pricing-item ul {
        min-height: auto;
    }

    .onboarding-area2 .pricing-footer {
        position: relative;
    }

    .onboarding-area2-dental .onboarding-dental-content-left button.btn-secondary {
        width: 100%;
        margin-top: 50px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item button {
        width: 100%;
    }

    .transaction-msg {
        text-align: center;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item button.m-3.px-4.py-2.bg-info-green.text-white.btn.btn-info {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col {
        padding: 0;
        order: 2;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col {
        padding: 0;
        order: 1;
        margin-bottom: 30px;
    }

    .onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-clear-btn {
        margin-left: 0px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .pricing-cards-img {
        width: 100%;
        margin-left: 0;
    }

    .error-row h1 {
        font-size: 170px;
    }

    .error-row h2 {
        font-size: 24px;
    }

    .error-row h3 {
        font-size: 20px;
    }

    .form-check,
    .form-check-label {
        font-size: 14px !important;
    }

    .react-datepicker-wrapper input,
    .form-control,
    .form-select {
        font-size: 14px !important;
    }

    .react-datepicker__input-container .react-datepicker__calendar-icon {
        top: 8px;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-text,
    .confirmation-thanks-container .confirmation-member-card {
        height: auto;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
        transform: translate(-50%, 0%);
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .react-datepicker button {
        width: 32px;
    }

    .onboarding-area-lg-footer .p-footer {
        padding-left: 32px !important;
        padding-right: 32px !important;
    }

    .quick-link-content .link-my img.img-fluid {
        width: 160px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-button span {
        font-size: 16px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-body {
        font-size: 14px;
    }
}

@media (max-width: 755px) {
    .onboarding-area2 .pricing-title-center h1 {
        font-size: 30px;
    }

    .hero-sec-title {
        font-size: 30px;
    }

    .value-product-title,
    .value-member-title {
        font-size: 22px;
    }
}

@media (max-width: 741px) {

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row {
        padding: 48px 20px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 20px;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title .pricing-text-underline svg.pricing-text-underline-img {
        width: calc(70% + 0.75em);
    }
}

@media (max-width: 726px) {
    .joining-title-center.onboarding-title-2 h1 {
        font-size: 30px;
    }
}

@media (max-width: 715px) {
    .confirmation-thanks-container .confirmation-thanks-subtitle {
        font-size: 21px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-title,
    .confirmation-thanks-container .confirmation-thanks-paragraph {
        font-size: 14px;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title .pricing-text-underline svg.pricing-text-underline-img {
        width: calc(80% + 0.75em);
    }
}

@media (max-width: 695px) {

    .joining-title-center h1,
    .joining-title-center.onboarding-title-2 h1 {
        font-size: 27px;
    }
}

@media (max-width: 658px) {

    .onboarding-checkout-content .secure-check-title h1 {
        font-size: 21px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h2.text-info-green {
        font-size: 20px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button {
        font-size: 16px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-collapse div.h4 {
        font-size: 16px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button::after {
        background-size: 78%;
        margin-top: 5px;
        left: 18px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button:not(.collapsed)::after {
        background-size: 78%;
        margin-top: 5px;
        left: 15px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button span {
        margin-left: 30px;
    }

    .form-check,
    .form-check-label {
        font-size: 13px !important;
    }

    .react-datepicker-wrapper input,
    .form-control,
    .form-select {
        font-size: 13px !important;
        height: 40px;
    }

    .react-datepicker__input-container .react-datepicker__calendar-icon {
        top: 4px;
    }

    .onboarding-checkout-content .auth-tab-content .auth-consent-description {
        font-size: 13px;
    }

    .onboarding-checkout-content .auth-tab-content p {
        font-size: 13px;
    }

    .checking-msg,
    .checking-info-col .checking-info {
        font-size: 13px;
    }

    .onboarding-content .form-check-content-row .custom-user-radio-label input.custom-user-radio-input {
        height: 13px;
        width: 13px;
    }

    .onboarding-content .form-check-content-row .custom-user-radio-label span.radio-label {
        font-size: 13px;
    }

    .onboarding-checkout-children-table thead.onboarding-checkout-children-table-thead tr th,
    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item label.form-label {
        font-size: 13px;
    }
}

@media (max-width: 651px) {

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row {
        padding: 48px 24px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 24px;
    }

    .confirmation-thanks-container .confirmation-thanks-subtitle {
        font-size: 18px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-title,
    .confirmation-thanks-container .confirmation-thanks-paragraph {
        font-size: 12px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-icon {
        font-size: 21px;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-icon {
        font-size: 25px;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-title {
        font-size: 21px;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-text h6 {
        font-size: 11px;
    }

    .confirmation-thanks-container .confirmation-member-card .order-card-title {
        font-size: 31px;
    }
}

@media (max-width: 643px) {
    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title {
        font-size: 32px;
    }
}

@media (max-width: 640px) {
    .public-layout-header {
        padding: 20px 0;
    }

    .header-logo {
        margin-left: 16px;
    }

    .header-logo-scroll-time {
        margin-left: 2px;
    }

    .public-layout-header.public-layout-header-scroll-time {
        padding: 20px 13px;
    }

    .public-layout-header.public-layout-header-scroll-time .nav-collapse-menu-icon {
        margin-right: 3px;
    }

    .checkout-next-pre-button.btn-primary.btn-lg {
        width: 70%;
    }

    .form-check-content-row .form-check-img-col {
        width: 50%;
        display: flex;
        justify-content: center;
    }

    .w-mbl-radio-img.w-100 {
        width: auto !important;
        height: 115px;
    }

    .onboarding-content .form-check-content-row {
        margin: 0;
    }

    .onboarding-area-lg-footer,
    .onboarding-area-sm-footer,
    .onboarding-dental-row,
    .onboarding-area2,
    .onboarding-checkout-content {
        padding: 48px 17px;
    }

    .onboarding-compare-table-area2 {
        padding: 0 17px;
    }

    .joining-mt {
        margin-top: 0 !important;
    }

    .joining-adjust {
        padding-top: 5px !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .joining-adjust.join-mb-md {
        margin-bottom: 20px !important;
    }

    .onboarding-content {
        padding: 0 15px 48px 15px;
    }

    .value-benefit-row {
        padding-left: 15px;
        padding-right: 15px;
    }

    .onboarding-content.product-pre-content {
        padding: 15px;
    }

    .plans-text-desktop {
        display: none;
    }

    .plans-text-mbl {
        display: block;
    }

    .pre-btn-mt {
        margin-top: 35px !important;
    }

    .onboarding-progress-content .progress {
        height: 10px;
    }

    .mt-md-next-btn.mt-5 {
        margin-top: 0px !important;
    }

    .onboarding-content .back-lg-link-btn {
        padding: 0 !important;
    }

    .onboarding-area-lg-footer .p-footer {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .value-benefit-row h2 {
        text-align: center;
        font-size: 29px;
    }

    .value-benefit-row h3 {
        text-align: center;
        font-size: 19px;
        margin-bottom: 8px;
    }

    .value-benefit-row p {
        text-align: center;
        font-size: 15px;
        line-height: 30px;
    }

    .value-benefit-list {
        margin-top: 40px;
    }

    .value-benefit-list svg {
        font-size: 22px;
        margin-top: 2px;
    }

    .value-benefit-list span {
        font-size: 13px;
        line-height: 24px;
    }

    .value-benefit-row a {
        display: block;
        text-align: center;
    }

    .value-benefit-wrap .value-benefit-row .col-second {
        display: none;
    }

    .value-benefit-wrap .value-benefit-row .value-benefit-img.mbl {
        display: block;
        margin: 5px;
    }

    .value-benefit-wrap .value-benefit-row:nth-child(even) .value-benefit-img,
    .value-benefit-wrap .value-benefit-row .value-benefit-img {
        padding: 0;
        border: none;
        background: transparent;
        box-shadow: 1px 1px 4px 4px rgb(0 0 0 / 14%);
    }

    .hero-sec-title {
        font-size: 40px;
    }

    .product-pre-title {
        font-size: 29px;
        text-align: center;
    }

    .product-pre-sub-title {
        font-size: 19px;
        text-align: center;
    }

    .product-pre-para.mb-4 {
        font-size: 14px;
        text-align: center;
    }

    .product-pre-para.mb-0 {
        font-weight: 700;
    }

    .product-pre-frame.desktop {
        display: none;
    }

    .product-pre-frame.mbl {
        display: block;
    }

    .value-product-title,
    .value-member-title {
        font-size: 29px;
    }

    .onboarding-area2-dental .onboarding-dental-content-left h2 {
        font-size: 27px;
    }

    .row.wave-margin {
        margin-top: 166px;
    }

    .bg-black-membership-tag-wave h3,
    .bg-membership-tag-wave h3 {
        margin-top: 129px;
        font-size: 27px;
    }

    .value-premier-img-content img.img-fluid {
        width: 150px;
    }

    .value-premier-para-content {
        margin: 0;
    }

    .value-premier-para-content p {
        text-align: center;
        font-size: 12px;
        line-height: 24px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-button span {
        font-size: 10px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-button::after {
        height: 17px;
        width: 17px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-body {
        font-size: 10px;
    }

    .premier-question-accordion-content .premier-question-accordion-item {
        border: 1px solid #e5f2fa !important;
        margin-bottom: 10px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-button {
        padding: 10px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-button::after {
        left: 10px;
    }

    .value-premier-wrap .premier-note {
        font-size: 10px;
        text-align: center;
    }

    .value-met-img-content {
        margin-top: 65px;
        margin-bottom: 0;
    }

    .value-met-img-content img.img-fluid {
        width: 200px;
    }

    .value-met-para-content p {
        text-align: center;
        font-size: 12px;
        line-height: 24px;
    }
}

@media (max-width: 586px) {

    .confirmation-thanks-container .confirmation-info-card .info-card-title,
    .confirmation-thanks-container .confirmation-thanks-paragraph {
        font-size: 11px;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-text h6 {
        font-size: 10px;
    }
}

@media (max-width: 575px) {

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .spouse-info-col {
        width: 100%;
    }

    .confirmation-thanks-container .confirmation-thanks-subtitle {
        font-size: 16px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-button span {
        font-size: 14px;
        margin-left: 35px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-body {
        font-size: 12px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-button::after {
        height: 27px;
        width: 27px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-body {
        font-size: 14px;
    }
}

@media (max-width: 561px) {

    .onboarding-area2-dental .onboarding-dental-content-left button.btn-secondary {
        margin-top: 25px;
    }
}

@media (max-width: 539px) {

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h3 {
        font-size: 17px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5.text-black {
        font-size: 15px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5 {
        font-size: 15px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h4 {
        font-size: 17px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer h3.assistance-text {
        font-size: 15px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer a {
        font-size: 14px;
    }

    .error-row h1 {
        font-size: 150px;
    }

    .error-row h2 {
        font-size: 22px;
    }

    .error-row h3 {
        font-size: 18px;
    }

    .confirmation-thanks-container .confirmation-thanks-title {
        font-size: 42.5px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-title,
    .confirmation-thanks-container .confirmation-thanks-paragraph {
        font-size: 11px;
    }

    .confirmation-thanks-container .confirmation-info-card .info-card-icon {
        font-size: 20px;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-icon {
        font-size: 24px;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-title {
        font-size: 20px;
    }

    .confirmation-thanks-container .confirmation-member-card .member-card-text h6 {
        font-size: 9px;
    }

    .confirmation-thanks-container .confirmation-member-card .order-card-title {
        font-size: 30px;
    }
}

@media (max-width: 507px) {
    .onboarding-area-lg-footer .footer-text {
        font-size: 10px;
    }

    .onboarding-area-sm-footer .footer-text .copyright-area {
        font-size: 12px;
    }

    .error-row h1 {
        font-size: 130px;
    }

    .error-row h2 {
        font-size: 20px;
    }

    .error-row h3 {
        font-size: 16px;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title {
        font-size: 28px;
    }

    .bg-black-membership-tag-wave h3,
    .bg-membership-tag-wave h3 {
        font-size: 28px;
    }
}

@media (max-width: 475px) {

    .onboarding-content .form-check-content-row .custom-user-radio-label input.custom-user-radio-input {
        height: 12px;
        width: 12px;
    }

    .onboarding-content .form-check-content-row .custom-user-radio-label span.radio-label {
        font-size: 12px;
    }

    .form-check,
    .form-check-label {
        font-size: 12px !important;
    }

    .react-datepicker-wrapper input,
    .form-control,
    .form-select {
        font-size: 12px !important;
        height: 40px;
    }

    .react-datepicker__input-container .react-datepicker__calendar-icon {
        top: 4px;
    }

    .checking-msg,
    .checking-info-col .checking-info {
        font-size: 12px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h3 {
        font-size: 15px;
    }

    .onboarding-checkout-content .auth-tab-content .auth-consent-description {
        font-size: 12px;
    }

    .onboarding-checkout-content .auth-tab-content p {
        font-size: 12px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button {
        font-size: 14px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-collapse div.h4 {
        font-size: 14px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button span {
        margin-left: 25px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button::after {
        background-size: 67%;
        margin-top: 5px;
        height: 15px;
        width: 15px;
        left: 18px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button:not(.collapsed)::after {
        background-size: 67%;
        margin-top: 5px;
        height: 15px;
        width: 15px;
        left: 15px;
    }

    .onboarding-checkout-children-table thead.onboarding-checkout-children-table-thead tr th,
    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item label.form-label {
        font-size: 13px;
    }

    .onboarding-checkout-content .btn-info-green-full-submit-button {
        font-size: 17px !important;
    }

    .onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-clear-btn i,
    .onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-capture-btn i {
        font-size: 21px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5 {
        font-size: 13px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h4 {
        font-size: 18px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5.text-black {
        font-size: 13px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer h3.assistance-text {
        font-size: 13px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer a {
        font-size: 12px;
    }

    .hero-index-row .checkout-next-pre-button.btn-primary.btn-lg {
        width: 100%;
    }
}

@media (max-width: 460px) {
    .public-layout-header div.text-center.d-sm-block span {
        font-size: 15px;
    }
}

@media (max-width: 448px) {
    .onboarding-area-lg-footer .footer-text {
        font-size: 10px;
    }

    .onboarding-area-sm-footer .footer-text .copyright-area {
        font-size: 10px;
    }
}

@media (max-width: 435px) {

    .public-layout-header div.text-center.d-sm-block span {
        font-size: 14px;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title {
        font-size: 25px;
    }

    .bg-black-membership-tag-wave h3,
    .bg-membership-tag-wave h3 {
        font-size: 25px;
    }
}

@media (max-width: 428px) {
    .premier-question-accordion-content .premier-question-accordion-item .accordion-button span {
        font-size: 12px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-body {
        font-size: 10px;
    }
}

@media (max-width: 415px) {
    .premier-question-accordion-content .premier-question-accordion-item .accordion-button span {
        font-size: 14px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-body {
        font-size: 12px;
    }

    .public-layout-header div.text-center.d-sm-block span {
        font-size: 13px;
    }

    .mbl-year-list {
        font-size: 11px;
    }
}

@media (max-width: 404px) {
    .back-lg-link-btn svg {
        height: 47px;
        width: 30px;
    }

    .onboarding-content .back-lg-link-btn {
        margin-top: 11px !important;
    }
}

@media (max-width: 402px) {
    .premier-question-accordion-content .premier-question-accordion-item .accordion-button span {
        font-size: 11px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-body {
        font-size: 9px;
    }
}

@media (max-width: 395px) {

    .public-layout-header div.text-center.d-sm-block span {
        font-size: 12px;
    }

    .mbl-year-list {
        font-size: 9px;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title .pricing-text-underline svg.pricing-text-underline-img {
        width: calc(95% + 0.75em);
    }
}

@media (max-width: 390px) {

    .onboarding-area-lg-footer .footer-text,
    .onboarding-area-sm-footer .footer-text .copyright-area {
        font-size: 9px;
    }

    .joining-title-center.onboarding-title-2 h1,
    .joining-title-center h1 {
        font-size: 23px;
    }

    .onboarding-content .form-check-content-row .custom-user-radio-label input.custom-user-radio-input {
        height: 10px;
        width: 10px;
    }

    .onboarding-content .form-check-content-row .custom-user-radio-label span.radio-label {
        font-size: 10px;
    }

    .form-check,
    .form-check-label,
    .form-control {
        font-size: 10px !important;
    }

    .form-select {
        font-size: 10px !important;
        background-size: 9px 12px !important;
    }

    .react-datepicker-wrapper input,
    .form-control,
    .form-select {
        height: 39px;
    }

    .react-datepicker__input-container .react-datepicker__calendar-icon {
        top: 3px;
    }

    .checking-msg,
    .checking-info-col .checking-info {
        font-size: 10px;
    }

    .onboarding-checkout-content .auth-tab-content .auth-consent-description {
        font-size: 10px;
    }

    .onboarding-checkout-content .auth-tab-content p {
        font-size: 10px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button {
        font-size: 12px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-collapse div.h4 {
        font-size: 12px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button span {
        margin-left: 25px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button::after {
        background-size: 67%;
        margin-top: 5px;
        height: 15px;
        width: 15px;
        left: 18px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button:not(.collapsed)::after {
        background-size: 67%;
        margin-top: 5px;
        height: 15px;
        width: 15px;
        left: 15px;
    }

    .onboarding-checkout-children-table thead.onboarding-checkout-children-table-thead tr th,
    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item label.form-label {
        font-size: 10px;
    }

    .onboarding-checkout-content .auth-tab-content .auth-consent-description {
        font-size: 10px;
    }

    .onboarding-checkout-content .auth-tab-content p {
        font-size: 10px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .pricing-cards-img {
        margin-left: 5px;
    }

    .onboarding-checkout-content .btn-info-green-full-submit-button {
        font-size: 15px !important;
    }

    .onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-clear-btn i,
    .onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-capture-btn i {
        font-size: 19px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5 {
        font-size: 11px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h4 {
        font-size: 16px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5.text-black {
        font-size: 12px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer h3.assistance-text {
        font-size: 12px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer a {
        font-size: 10px;
    }

    .onboarding-checkout-content .secure-check-title h1 {
        font-size: 17px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h2.text-info-green {
        font-size: 17px;
    }

    .mbl-year-list {
        font-size: 7px;
    }
}

@media (max-width: 388px) {
    .premier-question-accordion-content .premier-question-accordion-item .accordion-button span {
        font-size: 12px;
    }

    .premier-question-accordion-content .premier-question-accordion-item .accordion-body {
        font-size: 11px;
    }
}

@media (max-width: 365px) {

    .public-layout-header div.text-center.d-sm-block span {
        font-size: 11px;
    }

    .error-row h1 {
        font-size: 120px;
    }

    .error-row h2 {
        font-size: 19px;
    }

    .error-row h3 {
        font-size: 15px;
    }

    .onboarding-area2 .pricing-item li {
        font-size: 11px;
    }

    .onboarding-area2 .pricing-item li i.mr-icon {
        margin-right: 5px;
    }
}

@media (max-width: 360px) {

    .onboarding-area-lg-footer .footer-text,
    .onboarding-area-sm-footer .footer-text .copyright-area {
        font-size: 8px;
    }
}

@media (max-width: 340px) {

    .public-layout-header div.text-center.d-sm-block span {
        font-size: 10px;
    }

    .error-row h1 {
        font-size: 110px;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title .pricing-text-underline svg.pricing-text-underline-img {
        width: calc(100% + 0.75em);
    }
}

@media (max-width: 334px) {
    .premier-question-accordion-content .premier-question-accordion-item .accordion-button span {
        font-size: 10px;
    }

    .onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title {
        font-size: 20px;
    }
}

@media (max-width: 331px) {

    .onboarding-area-lg-footer .footer-text,
    .onboarding-area-sm-footer .footer-text .copyright-area {
        font-size: 7px;
    }

    .error-row h1 {
        font-size: 100px;
    }

    .error-row h2 {
        font-size: 18px;
    }

    .error-row h3 {
        font-size: 14px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5.text-black {
        font-size: 11px;
    }

    .confirmation-thanks-container .confirmation-thanks-subtitle {
        font-size: 15px;
    }
}

@media (max-width: 321px) {
    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button {
        font-size: 11px;
    }

    .onboarding-checkout-content .secure-check-title h1 {
        font-size: 16px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h2.text-info-green {
        font-size: 16px;
    }
}

@media (max-width: 320px) {

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-collapse div.h4 {
        font-size: 11px;
    }

    .onboarding-checkout-children-table thead.onboarding-checkout-children-table-thead tr th,
    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item label.form-label {
        font-size: 10px;
    }

    .form-check,
    .form-check-label {
        font-size: 9px !important;
    }

    .react-datepicker-wrapper input,
    .form-control,
    .form-select {
        font-size: 9px !important;
        height: 37px;
    }

    .react-datepicker__input-container .react-datepicker__calendar-icon {
        top: 1px;
    }

    .onboarding-checkout-content .auth-tab-content .auth-consent-description {
        font-size: 9px;
    }

    .onboarding-checkout-content .auth-tab-content p {
        font-size: 9px;
    }

    .onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .pricing-cards-img {
        margin-left: 5px;
    }

    .onboarding-checkout-content .btn-info-green-full-submit-button {
        font-size: 14px !important;
    }

    .onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-clear-btn i,
    .onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-capture-btn i {
        font-size: 18px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5 {
        font-size: 11px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h4 {
        font-size: 15px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-body .h5.text-black {
        font-size: 11px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer h3.assistance-text {
        font-size: 11px;
    }

    .onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer a {
        font-size: 9px;
    }
}

@media print {
    .hide-on-print {
        display: none;
    }

    .print-media-page-break {
        page-break-before: always;
        padding-top: 40px;
    }
}