.checkout-next-pre-button {
  font-family: Mulish;
  font-weight: 600;
  font-style: normal;
}

.custom-user-radio-button {
  background-color: #c6c6c6;
  transition: background-color 0.6s;
}

.custom-user-radio-button-active {
  background-color: var(--secondary-clr);
}
.sigCanvas {
  border: 1px dashed #ced4da;
}

.text-error {
  font-size: 120px;
  @media (max-width: 575.98px) {
    font-size: 86px;
  }
}
.error-text {
  color: #ff3d60;
  position: relative;
  .error-img {
    position: absolute;
    width: 120px;
    left: -15px;
    right: 0;
    bottom: 47px;
    @media (max-width: 575.98px) {
      width: 86px;
      left: -12px;
      bottom: 38px;
    }
  }
}
