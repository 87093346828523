.plans-view-content {
  background-color: #eff3f56b;
}

.product-header-row {
  align-items: center;
}

.product-header-row h3.product-header-title {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 30px;
}

.product-multi-view-card-row .product-card {
  margin: 15px 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border: 0;
  cursor: pointer;
}

.product-multi-view-card-row .product-card:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
  transition: transform 0.5s ease;
}

.product-multi-view-card-row .product-card-image {
  border-radius: var(--primary-border-radius) var(--primary-border-radius) 0px 0px;
}

.product-multi-view-card-row .product-name {
  font-size: 16px;
  font-weight: bold;
  min-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product-multi-view-card-row .product-price-group {
  font-weight: bolder;
  margin-top: 10px;
}

.product-multi-view-card-row .product-price {
  font-size: 23px;
}

.product-multi-view-card-row .product-mrp-price {
  text-decoration: line-through;
  margin: 0px 10px;
  font-size: 13px;
  color: rgba(128, 128, 128, 0.781);
}

.product-multi-view-card-row .product-discount {
  font-size: 16px;
}

.product-add-button {
  background: var(--secondary-clr) !important;
  border: 0 !important;
  padding: 8px 6px !important;
}

.product-add-button:hover,
.product-add-button:focus,
.product-add-button::selection,
.product-add-button:active {
  background: #12a594 !important;
}

.product-add-button:disabled:hover,
.product-add-button:disabled:focus,
.product-add-button:disabled::selection,
.product-add-button:disabled:active {
  background: var(--secondary-clr) !important;
}

/* ***Single product start*** */

.onboarding-content .back-lg-link-btn.single-product-back {
  margin-left: -11px;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.single-product-view-row .single-product-left-view-content {
  flex-wrap: nowrap;
}

.overflow-auto {
  overflow: auto;
}

.single-product-view-row .single-product-left-view-content .single-product-left-img-content {
  padding-left: 0;
}

.single-product-view-row .single-left-product-view-col {
  margin: 20px 13px;
  margin-bottom: 0;
}

.single-product-view-row .single-product-left-view-content .single-product-left-img-content .single-product-left-img {
  max-width: 100%;
  height: auto;
  cursor: pointer;
  margin-bottom: 10px;
}

.single-product-view-row .single-product-left-view-content .single-product-left-img-content .single-product-left-img.active {
  border: 3px solid var(--secondary-clr);
}

.single-product-view-row .h1.single-product-title {
  font-weight: 600;
  font-size: 28px;
}

.single-product-view-row p.single-product-pera {
  font-size: 16px;
}

.single-product-view-row .single-product-price-group {
  font-weight: 500;
}

.single-product-view-row .single-product-price-group .single-product-price {
  font-size: 23px;
}

.single-product-view-row .single-product-price-group .single-product-mrp-price {
  text-decoration: line-through;
  margin: 0px 10px;
  font-size: 13px;
  color: #808080c7;
}

.single-product-view-row .single-product-price-group .single-product-discount {
  font-size: 16px;
}

.single-product-btn-grp .single-product-count-content .single-product-count-btn-grp {
  width: 100%;
}

.single-product-btn-grp .single-product-count-content .single-product-count-btn-grp .count-btn {
  background: black;
  line-height: 1px;
}

.single-product-btn-grp .single-product-count-content .single-product-count-btn-grp .single-product-count-input {
  width: 60px;
  height: 40px;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

.single-product-btn-grp .single-product-count-content {
  padding-right: 0;
}

.single-product-btn-grp .single-product-count-add-content {
  padding-left: 0;
}

.offcanvas.offcanvas-end.off-canvas-cart h3.text-info-green {
  font-size: 28px;
}

.offcanvas.offcanvas-end.off-canvas-cart .offcanvas-header .btn-close {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23cfcfcf' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
  font-size: 27px;
}

.border-info-green {
  border-color: var(--secondary-clr) !important;
}

.shopping-cart-offsidebar-img-container img {
  width: 100%;
  height: auto;
}

.shopping-cart-offsidebar-count-input.form-control {
  width: 58px;
  height: 31px;
}

.btn.shopping-cart-offsidebar-product-remove-btn {
  font-size: 19px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 5px;
}

.shopping-cart-offsidebar-card {
  cursor: pointer;
}

.shopping-cart-offsidebar-card:hover {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.02);
  transition: transform 0.5s ease;
}

.text-gray-light {
  color: lightgray;
}

.data-not-found-icon.text-gray-light {
  font-size: 70px;
}

.refills-card-container .refills-card {
  margin-bottom: 15px;
  border: none;
  box-shadow: 0 8px 16px #00000026;
}

.refills-card-container .refills-card.active {
  /* border: 1px solid var(--secondary-clr); */
  box-shadow: 0px 0px 3px 0px #12a594;
}

.refills-card-container .refills-card:last-child {
  margin-bottom: 0px;
}

.refills-card-container .refills-card:hover {
  transform: scale(1.02);
  transition: transform 0.5s ease;
}

.refills-card-input-holder .form-check-input:checked {
  background-color: var(--secondary-clr);
  border-color: var(--secondary-clr);
}

.refills-card-input-holder .form-check-input {
  cursor: pointer;
}

.refills-card-container .refills-card .refills-card-text-holder {
  width: 100%;
}

.refills-card-container .refills-card .refills-card-text-holder .refills-small-text-1 {
  font-size: 12px;
}

.refills-card-container .refills-card .refills-card-text-holder .refills-small-text-2 {
  font-size: 13px;
  text-align: right;
}

.refills-card-container .refills-card .refills-card-text-holder .refills-small-footer-text {
  font-size: 12px;
}

.product-set-container .product-set-title {
  margin-bottom: 7px;
}

.product-routine-info-row .info-card-row {
  margin-bottom: 40px;
}

.product-routine-info-row .info-card-row .product-routine-info-img {
  height: 310px;
  width: 310px;
}

.product-routine-info-row .info-card-row.second-card {
  margin-bottom: 0;
}

.product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
.product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green {
  font-size: 28px;
}

.shadow-hr {
  display: block;
  height: 6px;
  width: 100%;
  content: "";
  border: none;
  background: radial-gradient(ellipse at 50% -50%, rgba(0, 0, 0, .5) 0, rgba(255, 255, 255, 0) 71.4%);
}

.product-routine-info-row .info-card-row.second-card .info-card-col-left {
  order: 2;
}

.product-routine-info-row .info-card-row.second-card .info-card-col-right {
  order: 1;
}

.product-routine-info-row .info-card-row.first-card .info-card-col-left {
  order: 1;
}

.product-routine-info-row .info-card-row.first-card .info-card-col-right {
  order: 2;
}

.product-routine-info-row .info-card-row .info-card-col-left,
.product-routine-info-row .info-card-row .info-card-col-right {
  margin: 0 auto;
}

.total-amount-sticky-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0 111px;
}

.total-amount-sticky-bar .total-amount-sticky-left-col {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #dcebe961;
}

.total-amount-sticky-bar .total-amount-sticky-right-col {
  padding-top: 20px;
  padding-bottom: 20px;
}

.total-amount-sticky-bar .amount-sticky-cost {
  font-size: 24px;
  font-weight: bold;
}

.total-amount-sticky-bar .amount-sticky-pay-today {
  font-size: 24px;
  font-weight: 500;
}

.info-card-row ul.list-unstyled li.d-flex.align-items-center.mb-2 span {
  font-size: 14px;
}

.onboarding-content.bg-question {
  background: url(../image/product/SleekDentalHeroBackground.webp);
  min-height: auto;
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.product-question-accordion-content .product-question-accordion-item {
  border: 2px solid var(--secondary-clr) !important;
  margin-bottom: 10px;
  border-radius: 8px 8px 0px 0px;
  color: var(--bs-black);
}

.product-question-accordion-content .product-question-accordion-item:hover {
  box-shadow: 0 0 20px 0 #14b8a546;
  transform: scale(1.02);
  transition: transform 0.5s ease;
}

.product-question-accordion-content .product-question-accordion-item label.form-label {
  font-weight: bold;
}

.product-question-accordion-content .product-question-accordion-item .accordion-button {
  box-shadow: none;
  border-radius: 8px 8px 0px 0px;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: bold;
  background: transparent;
  min-height: 60px;
}

.product-question-accordion-content .product-question-accordion-item .accordion-button::after {
  position: absolute;
  left: 20px;
  background-image: url("data:image/svg+xml,%3Csvg stroke='%2314b8a6' fill='%2314b8a6' stroke-width='0' viewBox='0 0 12 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M12 9H7v5H5V9H0V7h5V2h2v5h5v2z'%3E%3C/path%3E%3C/svg%3E");
}

.product-question-accordion-content .product-question-accordion-item .accordion-button:not(.collapsed)::after {
  transform: none;
  background-image: url("data:image/svg+xml,%3Csvg stroke='%2314b8a6' fill='%2314b8a6' stroke-width='0' version='1' viewBox='0 0 48 48' enable-background='new 0 0 48 48' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='8' y='21' width='32' height='6'%3E%3C/rect%3E%3C/svg%3E");
}

.product-question-accordion-content .product-question-accordion-item .accordion-button span {
  margin-left: 35px;
  color: var(--secondary-clr);
  font-size: 16px;
}

.product-question-accordion-content .product-question-accordion-item .accordion-body {
  font-size: 14px;
  color: var(--bs-gray);
}

.pro-chevron-dots-container {
  margin-top: 20px;
  font-size: 25px;
  text-align: center;
  color: lightgray;
}

.pro-chevron-icon {
  /* font-size: 45px; */
  cursor: pointer;
  position: absolute;
  color: var(--bs-white);
  background: #ffffff26;
  height: 80px;
  width: 35px;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.pro-chevron-icon:hover,
.pro-chevron-icon:active,
.pro-chevron-icon::selection {
  color: var(--bs-black);
}

.pro-chevron-icon.pre {
  left: 17px;
  border-radius: 0 6px 6px 0;
}

.pro-chevron-icon.next {
  right: -17px;
  border-radius: 6px 0 0 6px;
}

.pro-details-img-container {
  position: relative;
}

/* Chrome scrollbar custom start */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: #d6d6d6;
}

::-webkit-scrollbar-thumb {
  background: grey;
}

::-webkit-scrollbar-thumb:hover {
  background: #5f5f5f;
}

/* Chrome scrollbar custom end */
/* Firefox scrollbar custom start */
body,
body.modal-open,
.modal,
.offcanvas-body,
.modal-backdrop.show,
.overflow-auto,
html {
  scrollbar-color: grey #d6d6d6;
  scrollbar-width: thin;
}

/* Firefox scrollbar custom end */
/* ______________________________________________MEDIA START____________________________________________________ */
@media (max-width: 1330px) {
  .product-multi-view-card-row .product-name {
    font-size: 15px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1310px) {
  .product-multi-view-card-row .product-price {
    font-size: 20px;
  }

  .product-multi-view-card-row .product-mrp-price {
    font-size: 10px;
  }

  .product-multi-view-card-row .product-name {
    font-size: 13.3px;
    min-height: 41px;
  }

  .product-multi-view-card-row .product-discount {
    font-size: 13px;
  }
}

@media (max-width: 1214px) {

  .product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green {
    font-size: 22px;
  }

  .product-routine-info-row .info-card-row.second-card .info-card-col-right p.mb-2,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right p.mb-2,
  .single-product-view-row p.single-product-pera,
  .product-question-accordion-content .product-question-accordion-item .accordion-button span {
    font-size: 14px;
  }

  .product-set-container .pro-month-des,
  .product-set-container ul.list-unstyled span,
  .refills-card-container ul.list-unstyled span,
  .info-card-row ul.list-unstyled li.d-flex.align-items-center.mb-2 span,
  .product-question-accordion-content .product-question-accordion-item .accordion-body {
    font-size: 12px;
  }
}

@media (max-width: 1111px) {
  .product-routine-info-row .info-card-row .product-routine-info-img {
    height: 295px;
    width: 295px;
  }
}

@media (max-width: 1101px) {

  .product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green {
    font-size: 20px;
  }

  .product-routine-info-row .info-card-row.second-card .info-card-col-right p.mb-2,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right p.mb-2,
  .single-product-view-row p.single-product-pera,
  .product-question-accordion-content .product-question-accordion-item .accordion-button span {
    font-size: 12px;
  }

  .product-set-container .pro-month-des,
  .product-set-container ul.list-unstyled span,
  .refills-card-container ul.list-unstyled span,
  .info-card-row ul.list-unstyled li.d-flex.align-items-center.mb-2 span,
  .product-question-accordion-content .product-question-accordion-item .accordion-body {
    font-size: 10px;
  }
}

@media (max-width: 1076px) {
  .product-routine-info-row .info-card-row .product-routine-info-img {
    height: 285px;
    width: 285px;
  }
}

@media (max-width: 1030px) {
  .product-multi-view-card-row .product-price {
    font-size: 20px;
  }

  .product-multi-view-card-row .product-mrp-price {
    font-size: 10px;
  }

  .product-multi-view-card-row .product-name {
    font-size: 13.3px;
    min-height: 41px;
  }

  .product-multi-view-card-row .product-discount {
    font-size: 13px;
  }
}

@media (max-width: 1021px) {
  .product-question-accordion-content .product-question-accordion-item .accordion-button span {
    font-size: 11.5px;
  }
}

@media (max-width: 992px) {
  .total-amount-sticky-bar {
    padding: 0 60px;
  }

  .btn-vav-login {
    margin-right: 50px;
  }

  .product-routine-info-row .info-card-row .product-routine-info-img {
    height: 275px;
    width: 275px;
  }
}

@media (max-width: 918px) {

  .product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green {
    font-size: 17px;
  }
}

@media (max-width: 833px) {
  .single-product-btn-grp .single-product-count-content .single-product-count-btn-grp .single-product-count-input {
    width: 50px;
    font-size: 13px !important;
  }

  .product-routine-info-row .info-card-row .product-routine-info-img {
    height: 255px;
    width: 255px;
  }
}

@media screen and (min-width: 768px) and (max-width: 820px) {
  .product-multi-view-card-row .product-price {
    font-size: 16px;
  }

  .product-multi-view-card-row .product-mrp-price {
    font-size: 10px;
  }

  .product-multi-view-card-row .product-name {
    font-size: 12.1px;
  }

  .product-multi-view-card-row .product-discount {
    font-size: 12px;
  }

  .product-add-button {
    font-size: 12px;
  }
}

@media (max-width: 801px) {

  .product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green {
    font-size: 16.5px;
  }

  .product-green-ribbon .ribbon-text {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .btn-vav-login {
    display: none !important;
  }

  .product-routine-info-row .info-card-row .product-routine-info-img {
    height: auto;
    width: 100%;
  }

  .product-routine-info-row .info-card-row.second-card .info-card-col-right {
    order: 2;
  }

  .product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green {
    font-size: 26px;
    margin-top: 30px;
  }

  .product-routine-info-row .info-card-row.second-card .info-card-col-right p.mb-2,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right p.mb-2,
  .single-product-view-row p.single-product-pera,
  .product-question-accordion-content .product-question-accordion-item .accordion-button span {
    font-size: 16px;
  }

  .product-set-container .pro-month-des,
  .product-set-container ul.list-unstyled span,
  .refills-card-container ul.list-unstyled span,
  .info-card-row ul.list-unstyled li.d-flex.align-items-center.mb-2 span,
  .product-question-accordion-content .product-question-accordion-item .accordion-body {
    font-size: 14px;
  }

  .product-routine-info-row .info-card-row {
    margin-bottom: 0px;
  }

  .product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right p.mb-2,
  .product-routine-info-row .info-card-row.second-card .info-card-col-right p.mb-2,
  .product-set-container,
  .refills-card-container,
  .single-product-view-row p.single-product-pera,
  .single-product-view-row .h1.single-product-title {
    text-align: center;
  }

  .product-set-container ul,
  .refills-card-container ul {
    display: grid;
    justify-content: center;
  }

  .single-product-btn-grp {
    display: grid !important;
    justify-content: center;
  }

  .single-product-btn-grp .single-product-count-content,
  .single-product-btn-grp .single-product-count-add-content {
    padding: 0;
  }

  .single-product-btn-grp .single-product-count-add-content .product-add-button {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .product-routine-info-row .info-card-row .info-card-col-right {
    display: grid;
    justify-content: center;
  }
}

@media (max-width: 704px) {
  .product-green-ribbon .ribbon-text {
    font-size: 25px;
  }
}

@media (max-width: 645px) {
  .single-product-btn-grp .single-product-count-content .single-product-count-btn-grp .single-product-count-input {
    width: 40px;
    font-size: 12px !important;
    padding: 5px;
  }
}

@media (max-width: 645px) {
  .single-product-btn-grp .single-product-count-add-content .product-add-button {
    margin-bottom: 20px;
  }
}

@media (max-width: 610px) {
  .onboarding-content .back-lg-link-btn.single-product-back {
    margin-top: 0 !important;
  }
}

@media (max-width: 604px) {
  .product-green-ribbon .ribbon-text {
    font-size: 22px;
  }
}

@media (max-width: 575px) {
  .product-multi-view-card-row .product-name {
    min-height: auto;
  }

  .single-product-btn-grp .single-product-count-content .single-product-count-btn-grp {
    width: auto;
  }

  .single-product-btn-grp .single-product-count-content .single-product-count-btn-grp .single-product-count-input {
    width: 52px;
    height: 40px;
    font-size: 13px !important;
  }

  .single-product-btn-grp .single-product-count-add-content {
    margin-top: 24px;
  }
}

@media (max-width: 542px) {
  .product-green-ribbon .ribbon-text {
    font-size: 20px;
  }

  .bg-question .question-text {
    font-size: 23px;
  }

  .product-set-container .product-set-title,
  .refills-card-container .refills-card-title,
  .product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green {
    font-size: 20px;
  }
}

@media (max-width: 501px) {
  .product-green-ribbon .ribbon-text {
    font-size: 19px;
  }

  .product-question-accordion-content .product-question-accordion-item .accordion-button {
    padding: 10px;
  }

  .product-question-accordion-content .product-question-accordion-item .accordion-button::after {
    left: 9px;
  }

  .product-question-accordion-content .product-question-accordion-item .accordion-button span {
    font-size: 14px;
    margin-left: 25px;
  }

  .product-question-accordion-content .product-question-accordion-item .accordion-body {
    font-size: 12px;
  }

  .bg-question .question-text {
    font-size: 21px;
  }

  .product-set-container .product-set-title,
  .refills-card-container .refills-card-title,
  .product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green {
    font-size: 18px;
  }
}

@media (max-width: 481px) {
  .product-green-ribbon {
    padding: 40px;
  }

  .product-green-ribbon .ribbon-text {
    font-size: 18px;
  }

  .product-question-card.p-5 {
    padding: 48px 20px !important;
  }
}

@media (max-width: 445px) {
  .product-green-ribbon {
    padding: 20px;
  }

  .product-green-ribbon .ribbon-text {
    font-size: 17px;
  }
}

@media (max-width: 428px) {
  .product-question-accordion-content .product-question-accordion-item .accordion-button span {
    font-size: 12px;
  }

  .product-question-accordion-content .product-question-accordion-item .accordion-body {
    font-size: 10px;
  }
}

@media (max-width: 415px) {
  .bg-question .question-text {
    font-size: 19px;
  }

  .product-set-container .product-set-title,
  .refills-card-container .refills-card-title,
  .product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green {
    font-size: 16px;
  }

  .product-routine-info-row .info-card-row.second-card .info-card-col-right p.mb-2,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right p.mb-2,
  .single-product-view-row p.single-product-pera,
  .product-question-accordion-content .product-question-accordion-item .accordion-button span {
    font-size: 14px;
  }

  .product-set-container .pro-month-des,
  .product-set-container ul.list-unstyled span,
  .refills-card-container ul.list-unstyled span,
  .info-card-row ul.list-unstyled li.d-flex.align-items-center.mb-2 span,
  .product-question-accordion-content .product-question-accordion-item .accordion-body {
    font-size: 12px;
  }

  .single-product-btn-grp .single-product-count-add-content .product-add-button {
    font-size: 12px;
  }

  .single-product-btn-grp .single-product-count-content .single-product-count-btn-grp .single-product-count-input {
    height: 35px;
  }
}

@media (max-width: 402px) {
  .product-question-accordion-content .product-question-accordion-item .accordion-button span {
    font-size: 11px;
  }

  .product-question-accordion-content .product-question-accordion-item .accordion-body {
    font-size: 9px;
  }
}

@media (max-width: 388px) {
  .bg-question .question-text {
    font-size: 17px;
  }

  .product-set-container .product-set-title,
  .refills-card-container .refills-card-title,
  .product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green {
    font-size: 14px;
  }

  .product-routine-info-row .info-card-row.second-card .info-card-col-right p.mb-2,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right p.mb-2,
  .single-product-view-row p.single-product-pera,
  .product-question-accordion-content .product-question-accordion-item .accordion-button span {
    font-size: 12px;
  }

  .product-set-container .pro-month-des,
  .product-set-container ul.list-unstyled span,
  .refills-card-container ul.list-unstyled span,
  .info-card-row ul.list-unstyled li.d-flex.align-items-center.mb-2 span,
  .product-question-accordion-content .product-question-accordion-item .accordion-body {
    font-size: 11px;
  }
}

@media (max-width: 362px) {
  .bg-question .question-text {
    font-size: 15px;
  }

  .product-set-container .product-set-title,
  .refills-card-container .refills-card-title,
  .product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green {
    font-size: 14px;
  }
}

@media (max-width: 373px) {
  .shopping-cart-offsidebar-product-title {
    font-size: 14px;
  }
}

@media (max-width: 340px) {
  .product-header-row h3.product-header-title {
    font-size: 27px;
  }

  .single-product-view-row .single-product-price-group .single-product-price {
    font-size: 22px;
  }

  .shopping-cart-offsidebar-count-input.form-control {
    width: 30px;
    padding: 5px;
  }
}

@media (max-width: 335px) {
  .bg-question .question-text {
    font-size: 13px;
  }

  .product-set-container .product-set-title,
  .refills-card-container .refills-card-title,
  .product-routine-info-row .info-card-row.second-card .info-card-col-right h2.text-info-green,
  .product-routine-info-row .info-card-row.first-card .info-card-col-right h2.text-info-green {
    font-size: 14px;
  }
}

@media (max-width: 334px) {
  .shopping-cart-offsidebar-product-title {
    font-size: 13px;
  }

  .product-question-accordion-content .product-question-accordion-item .accordion-button span {
    font-size: 10px;
  }
}

@media (max-width: 322px) {
  .product-multi-view-card-row .product-name {
    font-size: 12.3px;
  }
}

/* ______________________________________________MEDIA END____________________________________________________ */