@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');

body {
  font-family: "Mulish", sans-serif !important;
  height: calc(100vh - 100px);
}

.disabled,
:disabled,
[disabled] {
  cursor: url(../image/not-allowed.svg), auto !important;
  pointer-events: auto !important;
}

.outline-0 {
  outline: 0 !important;
}

.text-info-green {
  color: var(--secondary-clr) !important;
}

.bg-info-green {
  background: var(--secondary-clr) !important;
}

.btn-outline-info-green {
  color: var(--secondary-clr) !important;
  border-color: var(--secondary-clr) !important;
}

.border-black {
  border-color: var(--bs-black) !important;
}

.grey-icon svg path {
  stroke: var(--bs-gray);
}

.input-group.group-invalid .grey-icon,
.input-group.group-valid .grey-icon {
  border: none;
}

.input-group.group-valid .grey-icon svg path {
  stroke: #198754;
}

.input-group.group-invalid .grey-icon svg path {
  stroke: #dc3545;
}

.onboarding-progress-content .progress {
  margin: 30px 0;
  height: 12px;
  background: #6c757d;
  border-radius: 10px;
}

.onboarding-progress-content .progress .progress-bar {
  border-radius: 10px;
  background-color: var(--secondary-clr);
  transition: width 0.6s ease;
  /* background-size: 40px 40px; */
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
}

.onboarding-progress-content .progress .progress-bar.progress-bar-animated {
  animation: 2s linear infinite progress-bar-stripes;
}

.onboarding-content {
  padding: 15px 100px 48px 100px;
}

.w-mbl-radio-img.w-100 {
  height: 223px;
}

.form-check-img-col {
  text-align: center;
}

.onboarding-content.product-pre-content {
  padding: 48px 100px;
}

.onboarding-content .form-check-content-row {
  margin: 0 48px;
}

.onboarding-content .form-check-content-row .form-check-input:checked {
  background-color: var(--secondary-clr);
  border-color: var(--secondary-clr);
  transition: background-color 0.6, border-color 0.6;
}

.onboarding-content .form-check-content-row .form-check-input {
  cursor: pointer;
}

.onboarding-content .form-check-content-row.row .form-check-label {
  cursor: pointer;
}

.checkout-next-pre-button.btn-primary {
  box-shadow: 0px 5px 40px 0px var(--secondary-clr) 80 !important;
  background: var(--secondary-clr) !important;
  border: 0;
  font-size: 18px;
}

.checkout-next-pre-button.btn-primary:hover,
.checkout-next-pre-button.btn-primary:focus,
.checkout-next-pre-button.btn-primary::selection,
.checkout-next-pre-button.btn-primary:active {
  background: #12a594 !important;
}

.checkout-next-pre-button.btn-primary:disabled:hover,
.checkout-next-pre-button.btn-primary:disabled:focus,
.checkout-next-pre-button.btn-primary:disabled::selection,
.checkout-next-pre-button.btn-primary:disabled:active {
  background: var(--secondary-clr) !important;
}

.checkout-next-pre-button.btn-primary.btn-lg {
  padding: 17px 40px;
  line-height: 21px;
}

.joining-title-center {
  margin-top: 60px;
}

.joining-title-center h1 {
  font-size: 30px;
}

.joining-title-center.onboarding-title-2 h1 {
  font-size: 35px;
}

.form-control {
  height: 50px;
  border-radius: 4px;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border: 1px solid #dc3545 !important;
}

.form-select.is-invalid,
.was-validated .form-select:invalid {
  border: 1px solid #dc3545 !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border: 1px solid #198754 !important;
}

.form-select.is-valid,
.was-validated .form-select:valid {
  border: 1px solid #198754 !important;
}

.input-group.group-valid {
  border: 1px solid #198754 !important;
  border-radius: 6px;
}

.input-group.group-invalid {
  border: 1px solid #dc3545 !important;
  border-radius: 6px;
}

.input-group.group-invalid .form-control.is-invalid,
.input-group.group-invalid .was-validated .form-control:invalid,
.input-group.group-valid .form-control.is-valid,
.input-group.group-valid .was-validated .form-control:valid {
  border: none !important;
}

.form-select {
  height: 50px;
  border-radius: 4px;
  box-shadow: none !important;
  border: 1px solid #ced4da !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

/* ****************Onboarding Area 2 Start***************** */
.onboarding-area2 {
  padding: 48px 100px;
}

.onboarding-area2 .pricing-title-center h1 {
  font-size: 55px;
}

.onboarding-area2 .pricing-title-center h2 {
  font-size: 20px;
  color: #c9f7ed;
}

.onboarding-area2 .fixed-shape {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  padding: 0;
  margin: 0;
}

.onboarding-area2 .fixed-shape img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  opacity: 0.3;
}

.onboarding-area2 .pricing {
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
}

.onboarding-area2 .pricing .shape {
  position: absolute;
  right: -200px;
  bottom: -200px;
  height: 400px;
  width: 400px;
  z-index: -1;
}

.onboarding-area2.default-padding.bg-gray {
  position: inherit;
}

.onboarding-area2 .pricing .single-item {
  padding: 0;
}

.onboarding-area2 .pricing .single-item:first-child .pricing-item {
  padding-left: 0;
}

.onboarding-area2 .pricing .single-item:last-child .pricing-item {
  padding-right: 0;
}

.onboarding-area2 .pricing-item {
  box-shadow: 0 0 10px #cccccc;
  border-radius: 8px;
  background: var(--bs-white);
  padding: 50px 15px;
}

.onboarding-area2 .pricing-header i {
  display: inline-block;
  font-size: 40px;
  margin-top: 33px;
  display: inline-block;
  color: var(--bs-white);
  height: 100px;
  width: 100px;
  line-height: 100px;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.onboarding-area2 .pricing-header h2 {
  font-weight: bold;
  margin-bottom: 0;
  /* font-size: 48px; */
  font-size: 41px;
  color: var(--secondary-clr);
}

.onboarding-area2 .pricing-header h3 {
  font-size: 36px;
  font-weight: bold;
  color: var(--secondary-clr);
  display: flex;
  flex-direction: column;
}

.onboarding-area2 .pricing-header h3 small {
  font-size: 16px;
  font-weight: 400;
  color: var(--secondary-clr);
}

.onboarding-area2 .pricing-header h4 {
  font-weight: bold;
  font-size: 24px;
  color: var(--secondary-clr);
}

.onboarding-area2 .pricing-header h5 {
  font-size: 16px;
  color: var(--secondary-clr);
}

.onboarding-area2 .pricing-header sup {
  font-size: 24px;
  top: -7px;
  font-weight: 500;
  margin-right: 3px;
}

.onboarding-area2 .pricing-header h2 sub {
  font-weight: 600;
  font-size: 19px;
  text-transform: lowercase;
}

.onboarding-area2 .pricing-header {
  padding-bottom: 30px;
  text-align: center;
}

.onboarding-area2 .pricing-item.active .pricing-header {
  border-color: rgba(255, 255, 255, 0.3);
}

.onboarding-area2 .single-item ul {
  display: inline-block;
  text-align: left;
  margin-bottom: 0;
}

.onboarding-area2 .pricing-item .footer {
  margin-top: 20px;
}

.onboarding-area2 .pricing-item li {
  line-height: 32px;
  font-size: 12.4px;
  text-transform: capitalize;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #f6f6f6;
}

.onboarding-area2 .pricing-item li:last-child {
  border-bottom: 0;
}

.onboarding-area2 .pricing-item li i {
  margin-left: 0;
  color: var(--secondary-clr);
  font-weight: 600;
}

.onboarding-area2 .pricing-item li i.mr-icon {
  margin-right: 15px;
}

.onboarding-area2 .pricing-item li i.ml-icon {
  margin-left: 5px;
}

.onboarding-area2 .pricing-item .pricing-footer i.ml-icon {
  margin-left: 10px;
}

.onboarding-area2 .pricing-item li i.fa-times-circle {
  color: #ff99af;
}

.onboarding-area2 .pricing-item li i.fa-times {
  color: #ff99af;
}

.onboarding-area2 .pricing-footer {
  text-align: center;
  background: #c9f7ed;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
}

.onboarding-area2 .pricing-footer button.text-uppercase.text-black {
  font-size: 14px;
}

.onboarding-area2 .pricing-footer button.text-uppercase.text-black.pricing-plan-btn {
  background: var(--secondary-clr);
  border-radius: 0 0 8px 8px;
  display: none;
}

.ml-external-icon {
  margin-top: -6px;
}

.onboarding-area2.plain .pricing .single-item.ocp .pricing-footer {
  border-radius: 0 0 0 8px;
}

.onboarding-area2.plain .pricing .single-item.pro .pricing-footer {
  border-radius: 0 0 8px 0;
}

.onboarding-area2 .pricing-header img {
  height: 100px;
  margin-top: 30px;
}

.onboarding-area2 .pricing-item li:nth-child(2n) {
  background: transparent;
}

.onboarding-area2 .pricing-footer a.btn-dark.effect:hover {
  background: #10aeea;
  color: var(--bs-white) !important;
  border-color: #10aeea;
}

.onboarding-area2 .pricing .single-item:nth-child(2) {
  z-index: 9;
}

.onboarding-area2 .pricing-item.active {
  transform: scale(1.07);
  -webkit-transform: scale(1.07);
  -moz-transform: scale(1.07);
  -ms-transform: scale(1.07);
  -o-transform: scale(1.07);
}

/* Pricing Plain */

.onboarding-area2.plain .pricing {
  margin-top: 0;
  margin-bottom: 0;
}

.onboarding-area2.plain .pricing-item.active {
  transform: inherit;
}

.onboarding-area2.plain .pricing .single-item {
  padding: 0;
  margin-bottom: 0px;
}

.onboarding-area2.plain .pricing .single-item .pricing-item {
  box-shadow: 5px 25px 25px #00000033;
  border: none;
  text-align: left;
  padding: 0;
  position: relative;
  z-index: 1;
  transition: all 0.35s ease-in-out;
  height: 100%;
}

.onboarding-area2.plain .pricing .single-item.ocp .pricing-item,
.onboarding-area2.plain .pricing .single-item.pro .pricing-item {
  background: var(--secondary-clr);
  box-shadow: none;
}

.onboarding-area2.plain .pricing .single-item .pricing-item ul {
  display: block;
  padding: 20px;
  min-height: 600px;
}

.onboarding-area2.plain .pricing .single-item .pricing-item.active,
.onboarding-area2.plain .pricing .single-item .pricing-item:hover {
  border: none;
  box-shadow: 5px 25px 25px #00000033;
}

.onboarding-area2.plain .pricing .single-item .pricing-item .pricing-header {
  text-align: center;
  border-bottom: none;
  margin-bottom: 0;
  padding: 20px;
  padding-bottom: 0;
}

.onboarding-area2.plain .pricing-card-row {
  /* background: var(--secondary-clr); */
  border-radius: 8px;
}

.onboarding-area2.plain .pricing .single-item .pricing-item .btn-onboarding2 {
  box-shadow: 0px 5px 40px 0px var(--secondary-clr) 80 !important;
  background: var(--secondary-clr);
  color: var(--bs-white);
  border: 0;
  font-size: 14px;
  font-weight: bold;
}

.onboarding-area2.plain .pricing .single-item .pricing-item .btn-onboarding2:hover,
.onboarding-area2.plain .pricing .single-item .pricing-item .btn-onboarding2:focus,
.onboarding-area2.plain .pricing .single-item .pricing-item .btn-onboarding2::selection,
.onboarding-area2.plain .pricing .single-item .pricing-item .btn-onboarding2:active {
  background: #12a594 !important;
  color: var(--bs-white) !important;
}

.onboarding-area2.plain .pricing .single-item.ocp .pricing-item .btn-onboarding2,
.onboarding-area2.plain .pricing .single-item.pro .pricing-item .btn-onboarding2 {
  background: var(--bs-white);
  color: var(--secondary-clr);
}

.onboarding-area2.plain .pricing .single-item.ocp .pricing-item .btn-onboarding2:hover,
.onboarding-area2.plain .pricing .single-item.ocp .pricing-item .btn-onboarding2:focus,
.onboarding-area2.plain .pricing .single-item.ocp .pricing-item .btn-onboarding2::selection,
.onboarding-area2.plain .pricing .single-item.ocp .pricing-item .btn-onboarding2:active,
.onboarding-area2.plain .pricing .single-item.pro .pricing-item .btn-onboarding2:hover,
.onboarding-area2.plain .pricing .single-item.pro .pricing-item .btn-onboarding2:focus,
.onboarding-area2.plain .pricing .single-item.pro .pricing-item .btn-onboarding2::selection,
.onboarding-area2.plain .pricing .single-item.pro .pricing-item .btn-onboarding2:active {
  background: #12a594 !important;
  color: var(--bs-white) !important;
}

.onboarding-area2.plain .pricing .single-item .pricing-item kbd.popular-tag {
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: var(--secondary-clr);
  padding: 9px 20px;
  line-height: 14px;
  font-size: 12px;
  font-family: inherit;
  border-radius: 8px;
}

.btn-onboarding2.btn-lg {
  padding: 17px 40px !important;
  line-height: 21px !important;
}

.onboarding-compare-table-area2 {
  padding: 0 48px;
}

.onboarding-compare-table-area2 table thead.onboarding-compare-table-thead th {
  font-size: 24px;
  font-weight: bold;
  color: var(--bs-black);
}

.onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody th.left-title {
  font-size: 24px;
  font-weight: bold;
  color: var(--bs-black);
}

.onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody th,
.onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody td {
  font-size: 14px;
  color: var(--bs-black);
}

.onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody td.plan-details-btn-container button.plan-details-btn {
  font-size: 12px;
  font-weight: bold;
}

.onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody td.plan-details-btn-container,
.onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody td.enroll-btn-container {
  padding: 8px;
}

.onboarding-compare-table-area2 table tbody.onboarding-compare-table-tbody td.enroll-btn-container button.enroll-btn {
  font-size: 18px;
}

.onboarding-area2-dental.bg-wave {
  background: url(../image/sleek-bg-wave.webp);
  height: 100%;
  width: 100%;
  transform: inherit;
  background-size: cover;
  background-repeat: no-repeat;
}

.onboarding-dental-row {
  padding: 48px;
}

.onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title {
  font-size: 40px;
  color: var(--bs-white);
  margin-top: 135px;
}

.onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title .pricing-text-underline {
  position: relative;
}

.onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title .pricing-text-underline svg.pricing-text-underline-img {
  position: absolute;
  top: 52%;
  left: 52%;
  width: calc(100% + 0.75em);
  height: calc(100% + 0.75em);
  transform: translate(-50%, -50%);
  overflow: visible;
  margin-top: 0;
}

.onboarding-area2-dental .onboarding-dental-row h1.onboarding-dental-title .pricing-text-underline svg.pricing-text-underline-img path {
  stroke: var(--bs-white);
  stroke-width: 10px;
  fill: none;
  stroke-dasharray: 1500;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.onboarding-area2-dental .onboarding-dental-content-left {
  padding-left: 0;
}

.onboarding-area2-dental .onboarding-dental-content-left h2 {
  color: var(--bs-white);
  font-size: 35px;
}

.onboarding-area2-dental .onboarding-dental-content-left p {
  color: var(--bs-white);
  font-size: 18px;
  line-height: 36px;
}

.onboarding-area2-dental .onboarding-dental-content-left h3 {
  color: var(--bs-white);
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
}

.onboarding-area2-dental .onboarding-dental-content-left button.btn-secondary {
  border: none;
  font-size: 18px;
  font-weight: bold;
  padding: 13px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.onboarding-area2-dental .onboarding-dental-content-left button.btn-secondary:hover {
  background-color: #e1e1e1 !important;
}

.onboarding-area2-dental .onboarding-dental-content-left button.btn-secondary .svg {
  stroke-width: 1;
  height: 15px;
}

/* ****** offcanvas Start ****** */
.offcanvas.offcanvas-end.off-canvas-pro,
.offcanvas.offcanvas-end.off-canvas-max,
.offcanvas.offcanvas-end.off-canvas-ocp {
  width: 800px;
}

.offcanvas.offcanvas-end p.small {
  font-size: 10px;
}

.offcanvas.offcanvas-end.off-canvas-pro .list-div-content,
.offcanvas.offcanvas-end.off-canvas-max .list-div-content {
  font-size: 12px;
}

.offcanvas.offcanvas-end .offcanvas-body .table tbody td {
  white-space: nowrap;
}

.offcanvas.offcanvas-end .offcanvas-header {
  padding-bottom: 0;
}

.offcanvas.offcanvas-end.off-canvas-pro .offcanvas-header .btn-close,
.offcanvas.offcanvas-end.off-canvas-max .offcanvas-header .btn-close,
.offcanvas.offcanvas-end.off-canvas-ocp .offcanvas-header .btn-close {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23cfcfcf' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
  font-size: 27px;
}

/* ****** offcanvas End ****** */
/* ****** Provider Info Modal Start ****** */
.modal-dialog.provider-info-modal.modal-dialog {
  max-width: 800px;
}

.modal-dialog.provider-info-modal.modal-dialog .modal-content {
  border-radius: 8px;
}

.modal-dialog.provider-info-modal.modal-dialog .modal-header .btn-close {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23cfcfcf' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
  font-size: 27px;
}

.modal-dialog.provider-info-modal.modal-dialog .modal-body {
  padding: 0 65px 48px 65px;
}

.modal-dialog.provider-info-modal.modal-dialog .modal-body .btn-modal-submit {
  color: white;
  background-color: var(--secondary-clr);
  padding: 14px;
  font-size: 15px;
  border: none;
}

.modal-dialog.provider-info-modal.modal-dialog .modal-body.provider-info-body-content .provider-info-img-content .provider-info-img {
  width: 100%;
}

.modal-dialog.provider-info-modal.modal-dialog .modal-body.provider-info-body-content p {
  line-height: 2;
  color: var(--bs-black);
}

/* ****** Provider Info Modal End ****** */
/* ****** Footer Start ****** */
.onboarding-area-lg-footer {
  padding: 48px;
}

.onboarding-area-lg-footer .footer-text {
  font-size: 12px;
  line-height: 24px;
  color: #6e6e6e;
}

.onboarding-area-sm-footer {
  padding: 48px;
}

.onboarding-area-sm-footer .footer-text .copyright-area {
  /* border-top: 1px solid rgba(255, 255, 255, 0.1); */
  /* padding-top: 45px; */
  font-size: 12px;
  line-height: 24px;
}

/* ****** Footer End ****** */
/* ****************Onboarding Area 2 End***************** */
/* ****************Onboarding Checkout Start***************** */
.onboarding-checkout-content {
  padding: 48px 100px;
}

.onboarding-checkout-content .secure-check-title h1 {
  font-size: 40px;
  font-weight: 700;
}

.onboarding-checkout-content .onboarding-checkout-left-col {
  padding-left: 0;
}

.onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row {
  margin-right: 50px;
}

.onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item {
  border: 1px solid #929aa340;
  margin-bottom: 10px;
  border-radius: 8px 8px 0px 0px;
  color: var(--bs-black);
}

.onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item label.form-label {
  font-weight: bold;
}

.onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button {
  box-shadow: none;
  border-radius: 8px 8px 0px 0px;
  padding: 20px 20px;
  font-size: 20px;
  font-weight: bold;
  background: transparent;
}

.onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button::after {
  position: absolute;
  left: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-10 -10 120 120'%3E%3Cpath d='M 50,0 L 60,10 L 20,50 L 60,90 L 50,100 L 0,50 Z' class='arrow' transform='translate(85,100) rotate(180)' stroke='%2314b8a6' fill='%2314b8a6' stroke-width='1'%3E%3C/path%3E%3C/svg%3E");
}

.onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button:not(.collapsed)::after {
  rotate: 90deg;
  transform: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-10 -10 120 120'%3E%3Cpath d='M 50,0 L 60,10 L 20,50 L 60,90 L 50,100 L 0,50 Z' class='arrow' transform='translate(85,100) rotate(180)' stroke='%2314b8a6' fill='%2314b8a6' stroke-width='1'%3E%3C/path%3E%3C/svg%3E");
}

.onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-button span {
  margin-left: 35px;
  color: var(--secondary-clr);
}

.onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .onboarding-checkout-accordion-content .onboarding-checkout-accordion-item .accordion-collapse div.h4 {
  font-size: 20px;
}

.onboarding-checkout-children-table thead.onboarding-checkout-children-table-thead tr th:first-child {
  padding: 0;
}

.onboarding-checkout-children-table tbody.onboarding-checkout-children-table-tbody tr td:first-child {
  padding-left: 0;
}

.onboarding-checkout-children-table tbody.onboarding-checkout-children-table-tbody tr td:last-child .btn-link.disabled {
  display: none;
}

.onboarding-checkout-content .onboarding-checkout-left-col .onboarding-checkout-tab-row .pricing-cards-img {
  width: 350px;
}

.onboarding-checkout-content .onboarding-checkout-right-col {
  padding-right: 0;
}

.onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card {
  box-shadow: 5px 20px 20px rgb(0 0 0 / 20%);
  position: sticky;
  top: 130px;
  border-radius: 8px;
}

.onboarding-checkout-content .onboarding-checkout-right-col .onboarding-checkout-order-summary-card .card-footer {
  border-radius: 0 0 8px 8px;
}

.onboarding-checkout-content hr.border-black {
  opacity: 1;
}

.onboarding-checkout-content .btn-info-green-full-submit-button {
  /* box-shadow: 0px 5px 40px 0px rgb(20 184 166 / 50%); */
  background: var(--secondary-clr) !important;
  color: var(--bs-white) !important;
  border: none !important;
  padding: 20px 0 !important;
  font-size: 20px !important;
  font-weight: bold !important;
  width: 100%;
}

.onboarding-checkout-content .btn-info-green-full-submit-button:hover,
.onboarding-checkout-content .btn-info-green-full-submit-button:focus,
.onboarding-checkout-content .btn-info-green-full-submit-button::selection,
.onboarding-checkout-content .btn-info-green-full-submit-button:active {
  background: #12a594 !important;
  color: var(--bs-white) !important;
}

.onboarding-checkout-content .btn-info-green-full-submit-button:disabled:hover,
.onboarding-checkout-content .btn-info-green-full-submit-button:disabled:focus,
.onboarding-checkout-content .btn-info-green-full-submit-button:disabled::selection,
.onboarding-checkout-content .btn-info-green-full-submit-button:disabled:active {
  background: var(--secondary-clr) !important;
}

button:disabled {
  opacity: 0.5 !important;
}

.onboarding-checkout-content .auth-tab-content .auth-consent-description {
  border: 1px solid #ddd;
  font-size: 0.8em;
  margin-top: 8px;
  max-height: 240px;
  overflow-y: scroll;
  padding: 8px;
}

.onboarding-checkout-content .auth-tab-content .sign-content .sign-canvas canvas.sigCanvas {
  cursor: url(../image/super-signature/pendark.webp), pointer;
}

.onboarding-checkout-content .btn-send {
  padding: 15px 29px !important;
  line-height: 17px !important;
  font-size: 14px !important;
}

.onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-capture-btn {
  border-color: var(--secondary-clr);
  color: var(--secondary-clr);
}

.onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-capture-btn:disabled {
  opacity: 0.5;
  border-color: var(--secondary-clr) !important;
  color: var(--secondary-clr) !important;
  background: var(--bs-white) !important;
}

.onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-capture-btn:hover,
.onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-capture-btn::selection,
.onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-capture-btn:active {
  color: var(--bs-white);
  background: var(--secondary-clr);
}

.onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-clear-btn,
.onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-capture-btn {
  padding: 3px 12px;
  line-height: 33px;
  margin-top: 10px;
}

.onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-clear-btn i,
.onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-capture-btn i {
  font-size: 21px;
  margin-right: 8px;
}

.onboarding-checkout-content .auth-tab-content .sign-content .sign-btn-content .sign-clear-btn {
  margin-left: 16px;
}

.onboarding-checkout-content .auth-tab-content .sign-img-content .sign-clear-btn {
  padding: 3px 12px;
  line-height: 33px;
}

.onboarding-checkout-content .auth-tab-content .sign-img-content .sign-clear-btn i {
  font-size: 21px;
  margin-right: 8px;
}

.onboarding-checkout-content .btn-send svg {
  margin-top: -3px;
}

/* ****************Onboarding Checkout End***************** */
/* Chrome, Safari, Edge, Opera Hide Arrows From Input Number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox Hide Arrows From Input Number */
input[type="number"] {
  -moz-appearance: textfield;
}

/* ****************Error Start***************** */
.error-row h1 {
  font-size: 240px;
  font-family: 'Bungee Inline', cursive;
  color: var(--secondary-clr);
}

.error-row h2 {
  font-size: 32px;
}

.error-row h3 {
  font-size: 28px;
}

/* ****************Error End***************** */
.onboarding-content .back-lg-link-btn {
  color: gray;
}

.onboarding-content .back-lg-link-btn:active,
.onboarding-content .back-lg-link-btn:focus,
.onboarding-content .back-lg-link-btn::selection,
.onboarding-content .back-lg-link-btn:hover {
  color: #14b8a636 !important;
}

/* .order-summary-product-name-content {
  display: flex;
  align-items: center;
  border-bottom: 2px dashed #f3f3f3;
  margin: 0;
  padding: 0px 0 15px 0;
}

.order-summary-product-name-content .order-summary-tooth-icon {
  font-size: 35px;
  margin-right: 13px;
  border-radius: 50%;
  border: 2px solid;
  padding: 5px;
} */

.joining-mt {
  margin-top: 48px !important;
}

.joining-adjust {
  padding-top: 48px !important;
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.onboard-pricing-text-desktop,
.plans-text-desktop {
  display: block;
}

.onboard-pricing-text-mbl,
.plans-text-mbl {
  display: none;
}

.pre-btn-mt {
  margin-top: 48px !important;
}

/* ** FOR iOS devices start ** */
.table-responsive {
  max-width: none;
  -webkit-overflow-scrolling: touch !important;
}

input::-webkit-date-and-time-value {
  text-align: left;
  text-align: -webkit-left;
  width: 100%;
  min-width: 150px;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

/* ** FOR iOS devices end ** */
.offcanvas.offcanvas-end .offcanvas-body.off-canvas-text-normal .table tbody th {
  white-space: normal;
  font-size: 14px;
}

.offcanvas.offcanvas-end .offcanvas-body.off-canvas-text-normal .table tbody td {
  white-space: normal;
  font-size: 14px;
}

.offcanvas.offcanvas-end .offcanvas-body.off-canvas-text-normal .table tbody td button {
  font-size: 12px;
}

.offcanvas.offcanvas-end .offcanvas-body h1 {
  font-size: 31px;
}

.offcanvas.offcanvas-end .offcanvas-body.off-canvas-text-normal .table tbody th.h4.left-title {
  font-size: 20px;
}

.offcanvas.offcanvas-end .offcanvas-body .enroll-btn {
  font-size: 18px;
}

.child-input-col {
  padding-left: 0 !important;
}

.child-input-col button {
  padding-right: 0 !important;
}

.child-input-col select.form-select {
  padding-right: 25px;
}

.mbl-child-card-responsive.card {
  display: none;
}

.order-summary-btn-grp {
  border: 1px solid #ced4da;
}

.order-summary-btn-grp .order-summary-btn {
  padding: 0 4px;
  color: black;
  background: transparent;
}

.order-summary-btn-grp .order-summary-count-input {
  width: 30px;
  height: 30px;
  border-bottom: none !important;
  border-top: none !important;
  padding: 0 !important;
}

.invalid-feedback-zip {
  font-size: 14px;
  margin-top: 4px;
  color: #dc3545;
}

/* react-datepicker CSS Start  */
.public-layout-body .react-datepicker-popper {
  z-index: 9;
}

.react-datepicker-wrapper {
  display: block;
  width: 100%;
}

.react-datepicker-wrapper input {
  width: 100%;
  height: 50px;
  padding: .375rem 2.3rem 0.375rem 0.75rem !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  right: 0;
  top: 8px;
  stroke-width: 0.5;
  width: 25px;
  color: #3d4348;
}

/* .react-datepicker__day-name {
  color: var(--bs-white) !important;
} */

/* .react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: var(--bs-white) !important;
} */

/* .react-datepicker__header {
  background-color: var(--bs-black) !important; */
/* border-color: var(--bs-black) !important;
} */

.react-datepicker {
  /* border-color: var(--bs-black) !important; */
  font-family: "Mulish", sans-serif !important;
}

/* .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: var(--bs-black) !important;
} */

/* .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-top-color: var(--bs-black) !important;
} */

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--secondary-clr) !important;
  color: var(--bs-white) !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {

  background-color: #216ba5 !important;
  color: var(--bs-white) !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  top: 12px !important;
}

.is-invalid .react-datepicker-wrapper input {
  border: 1px solid #dc3545;
}

.is-invalid .react-datepicker__input-container .react-datepicker__calendar-icon {
  color: #dc3545;
}

.is-invalid .invalid-feedback {
  display: block;
}

/* react-datepicker CSS End  */

.product-pre-title {
  font-size: 35px;
  font-weight: 700;
}

.product-pre-sub-title {
  font-size: 29px;
  font-weight: 700;
}

.product-pre-para {
  font-size: 18px;
  font-weight: 400;
}

.product-pre-list {
  padding-bottom: 15px;
}

.product-pre-list .w-100 {
  display: flex;
}

.product-pre-list span {
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  color: var(--bs-white);
  margin-left: 30px;
  margin-top: 1px;
  display: grid;
}

.product-pre-list svg {
  color: var(--bs-white);
  background-color: var(--secondary-clr);
  font-size: 24px;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
}

.product-pre-content.bg-black-wave {
  background: url(../image/sleek-bg-black-wave.png);
  height: 100%;
  width: 100%;
  transform: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: -30px;
}

.product-pre-frame.mbl {
  display: none;
}

.row.wave-margin {
  margin-top: 125px;
  margin-bottom: 25px;
}

.onboarding-content.bg-question.attach-fix-banner {
  background: url(../image/product/SleekDentalHeroTopBackground.png);
  min-height: 568px;
  background-attachment: unset;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-section {
  display: grid;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-sec-title {
  font-size: 56px;
  font-weight: 700;
}

.hero-sec-subtitle {
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 80px;
}

.value-member-wrap {
  text-align: center;
}

.value-member-title {
  font-size: 48px;
  font-weight: 700;
  margin-top: 32px;
  margin-bottom: 16px;
}

.value-member-para {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
}

.key-point-card {
  margin: 20px 0;
}

.key-point-card .key-point-icon {
  border-color: rgba(51, 51, 51, 0);
  border-width: 1px;
  background-color: var(--secondary-clr);
  height: 90px;
  width: 90px;
  border-radius: 50%;
  font-size: 45px;
  margin: 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.key-point-card .key-point-icon svg {
  color: var(--bs-white);
}

.key-point-card .key-point-title {
  font-size: 21px;
  color: #333333;
  font-weight: 700;
}

.key-point-card .key-point-subtitle {
  font-size: 16px;
  color: #737373;
  margin-top: 10px;
  margin-bottom: 20px;
}

.key-point-card:hover .key-point-icon {
  animation: pulse-animation 2s infinite;
  background-color: #3fa397;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(77, 77, 77, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(66, 66, 66, 0);
  }
}

.key-point-card:hover .key-point-title {
  transform: scale(1.02);
  transition-duration: 0.3s;
  color: var(--bs-black);
}

.key-point-card:hover .key-point-subtitle {
  transform: scale(1.02);
  transition-duration: 0.3s;
  color: #444444;
}

.value-product-wrap {
  text-align: center;
  /* padding-bottom: 80px; */
}

.value-product-membership-card.card {
  border: none;
  margin: 50px 0;
}

.value-product-membership-card.card:hover,
.value-product-membership-card.card:focus,
.value-product-membership-card.card::selection {
  transform: scale(1.02);
  transform-origin: 50% 50%;
  transition: all 0.2s ease-out;
  box-shadow: 0px 0px 9px #ffffffa8;
}

.value-product-title {
  font-size: 48px;
  font-weight: 700;
  margin-top: 50px;
  margin-bottom: 16px;
}

.value-product-para {
  font-size: 20px;
  font-weight: 400;
  color: #c9f7ed;
}

.membership-card-img-content {
  text-align: left;
}

.membership-card-img-content img.membership-card-img {
  height: 95px;
  width: auto;
  border-top-left-radius: 6px;
}

.membership-card-title-content {
  padding: 20px;
  margin: 0 20px;
}

.membership-card-title-content:hover {
  background-color: #fbfbfb;
}

.membership-card-title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 43px;
}

.membership-card-price-holder {
  margin-top: 25px;
}

.membership-card-price-holder .icon-dollar {
  font-size: 21px;
  color: #505050;
  font-weight: 700;
  top: -20px;
}

.membership-card-price-holder .price {
  font-size: 55px;
  font-weight: 700;
  color: var(--secondary-clr);
}

.membership-card-price-holder .decimal {
  font-size: 17px;
  font-weight: 700;
  color: var(--secondary-clr);
  top: -27px;
}

.membership-card-price-holder .starting-text {
  font-size: 11px;
  font-weight: 700;
  color: #888888;
  left: -19px;
}

.membership-card-price-holder p {
  font-size: 11px;
  font-weight: 700;
  color: #888888;
}

.membership-card-benefit-content {
  text-align: left;
  margin: 20px;
  min-height: 340px;
}

.membership-card-benefit-content .card-list {
  margin-bottom: 5px;
}

.membership-card-benefit-content span {
  font-size: 12px;
  color: var(--bs-black);
  line-height: 20px;
  margin-left: 8px;
}

.membership-card-benefit-content svg {
  color: var(--secondary-clr);
  font-size: 11px;
}

.value-product-membership-card button {
  padding: 13px 29px;
  font-size: 14px;
}

.btn.plan-details-btn {
  background-color: var(--secondary-clr);
  color: var(--bs-white);
}

.btn.plan-details-btn:hover,
.btn.plan-details-btn:focus,
.btn.plan-details-btn::selection {
  background-color: var(--secondary-clr) !important;
  color: var(--bs-black) !important;
}

.btn.provider-info-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: #c9f7ed;
  color: var(--bs-black);
}

.btn.provider-info-btn:hover,
.btn.provider-info-btn:focus,
.btn.provider-info-btn::selection {
  background-color: #98dfcf !important;
  color: var(--bs-white) !important;
}

.value-product-membership-card button .ml-icon {
  margin-left: 10px;
}

.hero-index-row .onboarding-area2-dental.bg-wave {
  background: url(../image/sleek-bg-wave-top-back.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-membership-tag-wave {
  background: url(../image/membership-tag-wave.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.bg-membership-tag-wave h3 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 82px;
}

.value-benefit-wrap {
  padding: 0;
}

.value-benefit-row {
  padding-left: 100px;
  padding-right: 100px;
  align-items: center;
}

.value-benefit-row h2 {
  font-size: 40px;
  font-weight: 700;
  color: var(--bs-black);
}

.value-benefit-row h3 {
  font-size: 32px;
  font-weight: 700;
  color: var(--secondary-clr);
  margin-bottom: 31px;
}

.value-benefit-row p {
  font-size: 18px;
  line-height: 36px;
  color: #333333;
}

.value-benefit-list .w-100 {
  display: flex;
  padding-bottom: 15px;
}

.value-benefit-list span {
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  color: var(--bs-black);
  margin-left: 30px;
  display: grid;
}

.value-benefit-list svg {
  color: var(--bs-white);
  background-color: var(--secondary-clr);
  font-size: 24px;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
}

.value-benefit-row a {
  font-size: 18px;
  text-decoration: none;
  outline: none;
  color: var(--secondary-clr) !important;
}

.value-benefit-text {
  margin: 80px 0;
}

.value-benefit-wrap .value-benefit-row .value-benefit-img.mbl {
  display: none;
}


.value-benefit-img {
  padding: 40px;
  background-color: #14b8a605;
  border-radius: 10px;
  box-shadow: 30px 25px 20px 10px rgba(0, 0, 0, 0.03);
  border-right: 6px solid var(--secondary-clr);
  margin: 80px 5px;
}

.value-benefit-img img {
  border-radius: 10px;
}

.value-benefit-wrap .value-benefit-row:nth-child(even) {
  background-color: #edf9f8;
}

.value-benefit-wrap .value-benefit-row:nth-child(even) .col-first {
  order: 2;
}

.value-benefit-wrap .value-benefit-row:nth-child(even) .col-second {
  order: 1;
}

.value-benefit-wrap .value-benefit-row:nth-child(even) .value-benefit-img {
  border-left: 6px solid var(--secondary-clr);
  border-right: none;
}

.bg-black-membership-tag-wave {
  background: url(../image/membership-tag-black-wave.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.bg-black-membership-tag-wave h3 {
  font-size: 30px;
  font-weight: 700;
  margin-top: 82px;
}

.value-premier-img-content {
  display: flex;
  justify-content: center;
  margin: 25px 0;
}

.value-premier-para-content {
  margin: 25px 0;
}

.value-premier-para-content p {
  font-size: 16px;
  line-height: 31px;
  color: var(--bs-black);
}

.premier-question-accordion-wrap {
  margin: 25px 0;
}

.premier-question-accordion-content .premier-question-accordion-item {
  border-bottom: 1px solid var(--bs-black) !important;
  /* margin-bottom: 10px; */
  border-radius: 8px 8px 0px 0px;
  color: var(--bs-black);
}

.premier-question-accordion-content .premier-question-accordion-item:hover {
  transform: scale(1.01);
  transition: transform 0.5s ease;
}

.premier-question-accordion-content .premier-question-accordion-item label.form-label {
  font-weight: bold;
}

.premier-question-accordion-content .premier-question-accordion-item .accordion-button {
  box-shadow: none;
  padding: 10px 0;
  font-size: 20px;
  font-weight: bold;
  background: transparent;
  min-height: 40px;
}

.premier-question-accordion-content .premier-question-accordion-item .accordion-button::after {
  position: absolute;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 1024 1024' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM704 536c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z'%3E%3C/path%3E%3C/svg%3E");
  background-color: transparent;
  background-size: cover;
  height: 28px;
  width: 28px;
}

.premier-question-accordion-content .premier-question-accordion-item:hover .accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg stroke='%23ffffff' fill='%2314b8a6' stroke-width='0' viewBox='0 0 1024 1024' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM704 536c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z'%3E%3C/path%3E%3C/svg%3E");
}

.premier-question-accordion-content .premier-question-accordion-item .accordion-button:not(.collapsed)::after {
  transform: none;
  background-image: url("data:image/svg+xml,%3Csvg stroke='%23ffffff' fill='%2314b8a6' stroke-width='0' viewBox='0 0 1024 1024' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM704 536c0 4.4-3.6 8-8 8H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h368c4.4 0 8 3.6 8 8v48z'%3E%3C/path%3E%3C/svg%3E");
  background-color: transparent;
}

.premier-question-accordion-content .premier-question-accordion-item .accordion-button span {
  margin-left: 35px;
  color: var(--secondary-clr);
  font-size: 16px;
}

.premier-question-accordion-content .premier-question-accordion-item .accordion-body {
  font-size: 14px;
  color: var(--bs-gray);
}

.value-premier-wrap .premier-note {
  font-style: italic;
  font-size: 16px;
  color: var(--bs-black);
  margin-bottom: 0;
}

.hero-index-row .product-question-card {
  border-top: 6px solid var(--secondary-clr) !important;
  border-radius: 10px;
  box-shadow: 0px 40px 40px 0px #b7c3d0 !important;
}

.value-met-img-content {
  display: flex;
  justify-content: center;
  margin: 25px 0;
  filter: grayscale(100%);
}

.value-met-img-content:hover {
  filter: none;
  transition-duration: 0.3s;
}

.value-met-para-content {
  margin: 25px 0;
}

.value-met-para-content p {
  font-size: 16px;
  line-height: 31px;
  color: var(--bs-black);
}

.value-blog-wrap .value-blog-content {
  align-items: center;
}

.value-blog-wrap .value-blog-content .value-blog-para {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: var(--secondary-clr);
}

.value-blog-wrap .value-blog-content .value-blog-title {
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
  color: var(--bs-black);
  margin-bottom: 16px;
}

.value-blog-wrap .value-blog-content .btn-dark {
  font-size: 14px;
  font-weight: 700;
  color: var(--bs-white);
  background-color: var(--bs-black);
  padding: 10px 30px;
  float: right;
}

.value-blog-card {
  line-height: 32px;
  width: 100%;
  margin: 50px 0;
}

.value-blog-card:hover {
  transform: translate(0px, -8px);
  transition-duration: 0.3s;
}

.value-blog-card .value-blog-card-category {
  font-size: 16px;
  color: var(--secondary-clr);
  margin: 16px 0 6px 0;
}

.value-blog-card .value-blog-card-title {
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  min-height: 65px;
}

.value-blog-card-link span {
  border-bottom: 2px solid #c9f7ed;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #333333;
}

.value-blog-card .img-blog-value {
  height: auto;
  width: 100%;
}

.below-content .hero-sec-subtitle {
  margin-bottom: 56px;
  font-size: 30px;
  font-weight: 400;
}

.onboarding-content.below-content {
  background: url(../image/product/SleekDentalHeroBelowBackground.png);
  min-height: auto;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 48px;
}

.quick-link-content .link-my {
  margin-top: 15px;
  white-space: normal;
  word-break: break-word;
}

.quick-link-menu-holder .menu-text-title {
  font-size: 12px;
  color: #c9f7ed;
  line-height: 24px;
}

.quick-link-menu-holder .menu-text-grp .menu-text {
  border: none;
  background: transparent;
  padding: 0;
  font-size: 16px;
  color: var(--bs-white);
  line-height: 32px;
  text-decoration: none;
}

.quick-link-menu-holder .menu-text-grp .menu-text:hover {
  color: var(--secondary-clr);
}

.quick-link-menu-holder .menu-text-grp .menu-text.active {
  color: var(--secondary-clr);
}

.menu-text-grp.menu-icon-group .menu-text {
  font-size: 25px;
  margin-right: 8px;
  width: auto;
  /* margin: 0 auto 0 auto; */
}

.quick-link-content hr.border-info-green {
  border-width: 2px;
  opacity: 1;
  margin: 48px 0;
}

#perfectplan2 {
  box-shadow: 0px 5px 20px 0px rgba(18, 184, 166, 0.8) !important;
  transform: translateY(0) !important;
  transition: all .2s, transform .5s !important;
}

.modal-dialog.provider-info-modal.home-info.modal-dialog h1.text-info-green {
  font-size: 30px;
  margin-bottom: 25px;
}

.modal-dialog.provider-info-modal.home-info.modal-dialog {
  max-width: 900px;
}

.modal-dialog.provider-info-modal.home-info.modal-dialog .modal-body {
  padding: 16px;
  height: 516px;
}

.modal-dialog.home-info td div.h6 small {
  font-size: 10px;
}

.modal-dialog.home-info .plan-details-list {
  font-size: 12px;
}

.modal-dialog.provider-info-modal.modal-dialog.home-info .modal-body.provider-info-body-content p,
.modal-dialog.provider-info-modal.modal-dialog.home-info .modal-body.provider-info-body-content ol {
  font-size: 12px;
}