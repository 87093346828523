.loader-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}
/* --------------------------Page Loader CSS--------------------------- */
.center-loader-container {
  height: calc(100vh - 0px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
.center-loader-container .loader-ring {
  height: 80px;
  width: 80px;
  border: var(--bs-spinner-border-width) solid #0daf9d59;
  border-right-color: transparent;
  --bs-spinner-animation-speed: 1s;
}
.center-loader-container .loader-ring::before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  rotate: 53deg;
  right: 3px;
  top: 7px;
  background: var(--secondary-clr);
  border-radius: 50%;
}
.center-loader-container h5 {
  letter-spacing: 2.5px;
}
.center-loader-container i.tooth-loader-icon {
  position: absolute;
  margin-top: 23px;
  margin-left: -56px;
  color: var(--secondary-clr);
}
/* ------------------------------------------------------------------------- */
/* --------------------------Area base Loader CSS--------------------------- */
.area-loader-container {
  height: auto;
  display: block;
  background: white;
  margin: 0;
  padding: 0;
}
.area-loader-container .loader-ring {
  height: 80px;
  width: 80px;
  border: var(--bs-spinner-border-width) solid #0daf9d59;
  border-right-color: transparent;
  --bs-spinner-animation-speed: 1s;
}
.area-loader-container .loader-ring::before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  rotate: 53deg;
  right: 3px;
  top: 7px;
  background: var(--secondary-clr);
  border-radius: 50%;
}
.area-loader-container i.tooth-loader-icon {
  position: absolute;
  margin-top: 23px;
  margin-left: -56px;
  color: var(--secondary-clr);
}
/* ----------------------------------------------------------------------------- */
